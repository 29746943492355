<template>
  <v-menu
    ref="menu"
    v-model="menuDate"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        :disabled="disabled"
        :error-messages="errorMessage"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      :active-picker.sync="activePicker"
      no-title
      :first-day-of-week="1"
      min="1950-01-01"
      :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)"
    />
  </v-menu>
</template>

<script>
import dateF from '../services/date.service'

export default {
  props: ['value', 'label', 'disabled', 'errorMessage'],
  data() {
    return {
      activePicker: null,
      menuDate: false,
    }
  },
  computed: {
    dateFormatted() {
      if (this.selectedDate) return dateF.formatBirthday(this.selectedDate)
      else return ''
    },
    selectedDate: {
      get() {
        return this.value
      },
      set(val) {
        this.saveDate(val)
      },
    },
  },
  watch: {
    menuDate(val) {
      // val && setTimeout(() => (this.activePicker = 'YEAR'))
      console.log(val)
    },
  },
  methods: {
    saveDate(date) {
      //this.$refs.menu.save(date)
      this.$emit('input', date)
      this.menuDate = false
    },
  },
}
</script>
