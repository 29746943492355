import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.maxWidth,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_vm._t("activator",function(){return [_c(VBtn,_vm._g(_vm._b({attrs:{"block":"","color":"info","text":""}},'v-btn',scope.attrs,false),scope.on),[_vm._v(" "+_vm._s(_vm.displayName)+" ")])]},null,scope)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('delete-dialog',{attrs:{"visible":_vm.deleteDialog,"headers":_vm.headersWithoutAction,"selected-item":_vm.selectedItem,"dialog-title":'Delete ' + _vm.displayName},on:{"closeDelete":_vm.closeDelete}}),_c(_vm.editComponent,{tag:"component",attrs:{"visible":_vm.editDialog,"selected-item":_vm.selectedItem,"dialog-title":_vm.dialogText,"database-module":_vm.databaseModule,"edit-params":_vm.editParams},on:{"closeEdit":_vm.closeEdit}})],_c(VCard,[_c('display-header',{attrs:{"display-name":_vm.displayName},on:{"onSearch":_vm.onSearch}}),_c(VCardText,{staticClass:"pb-0"},[_c(VContainer,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.allItems,"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Loading... Please wait!","show-expand":_vm.showExpanded,"expanded.sync":"expanded"},scopedSlots:_vm._u([_vm._l((_vm.filteredSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"item.actions",fn:function(scope){return [_vm._t("item.actions",function(){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.updateItem(scope.item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit item")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":"","color":"error","disabled":!(_vm.isAdmin || _vm.enableEdit)},on:{"click":function($event){return _vm.deleteItem(scope.item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete item")])]),_vm._t("addedActions",null,null,scope)]},null,scope)]}}],null,true)})],1)],1),_c(VDivider),_c('display-actions',{attrs:{"display-name":_vm.displayName,"is-loading":_vm.isLoading,"disabled-add":!(_vm.isAdmin || _vm.enableEdit)},on:{"exit":_vm.exit,"addItem":_vm.addItem,"refreshData":_vm.reloadData}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }