<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-subtitle class="white--text secondary">
          Truck#<strong>{{ truckId }}</strong
          >, Driver: <strong>{{ driverText }}</strong>
        </v-card-subtitle>

        <v-card-text>
          <v-container>
            <validation-observer ref="observer">
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Broker" rules="max:30">
                    <v-text-field
                      v-model.trim="item.broker"
                      label="Broker"
                      prepend-icon="mdi-human-greeting"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Load Number" rules="max:20">
                    <v-text-field
                      v-model.trim="item.loadNo"
                      label="Load Number"
                      prepend-icon="mdi-pound"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" sm="8">
                  <validation-provider v-slot="{ errors }" name="Address" rules="required">
                    <v-text-field
                      v-model="item.address"
                      label="City, State Zip"
                      prepend-icon="mdi-city"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="4">
                  <!-- Load type selector -->
                  <data-selector
                    label="Load Type"
                    database-module="loadTypes"
                    getter="loadTypes"
                    item-text="typeText"
                    item-value="id"
                    :not-empty="true"
                    :data.sync="item.loadTypeId"
                  />
                  <!--  -->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-switch v-model="item.range" label="Range" class="d-inline-block"></v-switch>
                </v-col>

                <!-- Date picker for range = false -->
                <v-col cols="12" sm="5">
                  <date-picker
                    :date.sync="item.dayFrom"
                    :label="item.range ? 'Appt date from' : 'Appt date'"
                    :not-empty="true"
                  />
                </v-col>
                <!-- Date picker for range = false -->

                <!-- Time picker for range = false -->
                <v-col cols="12" sm="4">
                  <!-- <time-picker
                    :time.sync="item.timeFrom"
                    :label="item.range ? 'Appt time from' : 'Appt time'"
                    :not-empty="true"
                  /> -->
                  <m-digital-time-picker v-model="item.timeFrom" />
                </v-col>
                <!--  -->
              </v-row>

              <v-row v-if="item.range">
                <v-spacer></v-spacer>

                <!-- Date picker for range = true -->
                <v-col cols="12" sm="5">
                  <date-picker :date.sync="item.dayTo" label="Appt date to" :not-empty="true" />
                </v-col>
                <!-- Date picker for range = true -->

                <!-- Time picker for range = true -->
                <v-col cols="12" sm="4">
                  <!-- <time-picker :time.sync="item.timeTo" label="Appt time to" :not-empty="true" /> -->
                  <m-digital-time-picker v-model="item.timeTo" />
                </v-col>
                <!--  -->
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model.trim="item.note" auto-grow label="Note" rows="4" row-height="30" clearable>
                  </v-textarea>
                </v-col>
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <edit-actions :disabled-submit="true" @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import crudEdit from '../../mixins/crudEdit'
import DatePicker from './_DatePicker.vue'
// import TimePicker from './_TimePicker.vue'
import DataSelector from './_DataSelector.vue'
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'
import MDigitalTimePicker from '../MDigitalTimePicker.vue'

extend('max', {
  ...max,
  message: '{_field_} must not exceed {length} characters.',
})

export default {
  components: {
    MDigitalTimePicker,
    DatePicker,
    // TimePicker,
    DataSelector,
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [crudEdit],
  props: ['visible', 'selectedItem', 'dialogTitle', 'databaseModule', 'editParams'],
  data() {
    return {
      defaultItem: {
        loadNo: '',
        broker: '',
        address: '',
        range: false,
        dayFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        timeFrom: '00:00',
        dayTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        timeTo: '00:00',
        loadTypeId: '',
        userId: '',
        note: '',
      },
    }
  },
  computed: {
    truckId() {
      return this.editParams?.truckId
    },
    driverText() {
      return this.editParams?.driverText
    },
    driverId() {
      return this.editParams?.driverId
    },
    activeUser() {
      return this.$store.getters['auth/activeUser']
    },
  },
  watch: {
    // da bi radio kako treba
    'item.range'(val) {
      if (val) {
        this.item.dayTo = this.item.dayTo ? this.item.dayTo : this.defaultItem.dayTo
        this.item.timeTo = this.item.timeTo ? this.item.timeTo : this.defaultItem.timeTo
      }
    },
  },
  methods: {
    submitFormatter(item) {
      item.driverId = this.driverId
      item.userId = this.activeUser.id
      if (item.dayFrom && item.timeFrom) {
        const [year, month, day] = item.dayFrom.split('-')
        const [hours, minutes] = item.timeFrom.split(':')
        //item.dateFrom = new Date(item.dayFrom + 'T' + item.timeFrom).toISOString()
        item.dateFrom = new Date(Date.UTC(year, month - 1, day, hours, minutes)).toISOString()
      } else {
        item.dateFrom = ''
      }
      if (item.range && item.dayTo && item.timeTo) {
        // item.dateTo = new Date(item.dayTo + 'T' + item.timeTo).toISOString()
        const [year, month, day] = item.dayTo.split('-')
        const [hours, minutes] = item.timeTo.split(':')
        item.dateTo = new Date(Date.UTC(year, month - 1, day, hours, minutes)).toISOString()
      } else {
        item.dateTo = ''
      }
      delete item.dayFrom
      delete item.dayTo
      delete item.timeFrom
      delete item.timeTo
    },
    updateFormatter(item) {
      // let dateFrom = new Date(new Date(item.dateFrom) - new Date().getTimezoneOffset() * 60000).toISOString()
      let dateFrom = item.dateFrom
      item.dayFrom = dateFrom.substring(0, 10)
      item.timeFrom = dateFrom.substring(11, 16)
      if (item.range) {
        // let dateTo = new Date(new Date(item.dateTo) - new Date().getTimezoneOffset() * 60000).toISOString()
        let dateTo = item.dateTo
        item.dayTo = dateTo.substring(0, 10)
        item.timeTo = dateTo.substring(11, 16)
      } else {
        item.dayTo = ''
        item.timeTo = ''
      }
    },
    insertFormatter(item, selectedItem) {
      if (selectedItem.action === 'append') {
        item.loadNo = selectedItem.loadNo
        item.broker = selectedItem.broker
        selectedItem.action = 'add'
      }
    },
  },
}
</script>
