<template>
  <v-dialog v-model="dialog" max-width="650px" persistent>
    <template>
      <delete-dialog
        :visible="deleteDialog"
        :headers="headersWithoutAction"
        :selected-item="selectedItem"
        :dialog-title="'Delete ' + displayName"
        @closeDelete="closeDelete"
      />
      <edit-dialog
        :visible="editDialog"
        :selected-item="selectedItem"
        :dialog-title="dialogText"
        :database-module="databaseModule"
        @closeEdit="closeEdit"
      />
    </template>
    <v-card>
      <display-header :display-name="displayName" @onSearch="onSearch" />

      <v-card-text class="pb-0">
        <v-container>
          <v-data-table
            :headers="headers"
            :items="allItems"
            :search="search"
            :loading="isLoading"
            loading-text="Loading... Please wait!"
          >
            <!-- Top table caption -->
            <template v-slot:top>
              <div class="info--text subtitle-1">
                for driver: <strong>{{ driverText }}</strong>
              </div>
            </template>
            <!--  -->

            <!-- Display action buttons in table -->
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    color="success"
                    :disabled="!isEditor"
                    @click="updateItem(item)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit item</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" color="error" :disabled="!isEditor" @click="deleteItem(item)" v-on="on">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete item</span>
              </v-tooltip>
            </template>
            <!--  -->
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-divider />

      <display-actions
        :display-name="displayName"
        :is-loading="isLoading"
        :disabled-add="!isEditor"
        @exit="exit"
        @addItem="addItem"
        @refreshData="reloadData"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteDialog from './_CommonDelete.vue'
import crudDisplay from '../../mixins/crudDisplay'
import DisplayHeader from './_CommonDisplayHeader.vue'
import DisplayActions from './_CommonDisplayActions.vue'

export default {
  components: {
    DeleteDialog,
    EditDialog: () => import('./driver-contact-info-edit.vue'),
    DisplayHeader,
    DisplayActions,
  },
  mixins: [crudDisplay],
  props: ['driverId', 'driverText', 'visible'],
  data() {
    return {
      databaseModule: 'driverContactInfo',
      displayName: 'Contact',
      headers: [
        { text: 'Contact Type', value: 'typeText' },
        { text: 'Contact Info', value: 'contactInfo' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      emitExit: false,
    }
  },
  computed: {
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
    allItems() {
      if (!this.driverId) return []
      return this.$store.getters[this.databaseModule + '/getContactsByDriverId'](this.driverId)
    },
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        this.selectedItem.driverId = this.driverId
        if (!value && this.emitExit) {
          this.emitExit = false
          this.$emit('closeContact', this.selectedItem)
        }
      },
    },
  },
  methods: {
    exit() {
      this.emitExit = true
      this.dialog = false
      this.reload = true
    },
  },
}
</script>
<style scoped>
@import '../../assets/styles/crudDialog.css';
</style>
