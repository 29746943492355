<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <template>
      <delete-dialog
        :visible="deleteDialog"
        :headers="headersWithoutAction"
        :selected-item="selectedItem"
        :dialog-title="'Delete ' + displayName"
        @closeDelete="closeDelete"
      />
      <edit-dialog
        :visible="editDialog"
        :selected-item="selectedItem"
        :driver-text="driverText"
        :dialog-title="dialogText"
        :database-module="databaseModule"
        @closeEdit="closeEdit"
      />
    </template>
    <v-card>
      <display-header :display-name="displayName" @onSearch="onSearch" />

      <v-card-text class="pb-0">
        <v-container>
          <v-data-table
            :headers="headers"
            :items="allItems"
            :search="search"
            :loading="isLoading"
            :show-expand="true"
            expanded.sync="expanded"
            loading-text="Loading... Please wait!"
            :hide-default-footer="true"
          >
            <!-- Top table caption -->
            <template v-slot:top>
              <div class="subtitle-1">
                for driver: <strong>{{ driverText }}</strong
                >, from: <strong>{{ formattedDate(date) }}</strong>
              </div>
            </template>
            <!--  -->

            <!-- dateFrom formatted -->
            <template v-slot:item.dateFrom="{ item }">
              {{ formattedDate(item.dateFrom) }}
            </template>
            <!--  -->
            <!-- dateTo formatted -->
            <template v-slot:item.dateTo="{ item }">
              {{ formattedDate(item.dateTo) }}
            </template>
            <!--  -->

            <!-- Display action buttons in table -->
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    color="success"
                    :disabled="!isEditor"
                    @click="updateItem(item)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit item</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" color="error" :disabled="!isEditor" @click="deleteItem(item)" v-on="on">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete item</span>
              </v-tooltip>
            </template>
            <!--  -->

            <!-- EXPAND BUTTON -->
            <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
              <v-btn v-if="item.note" color="error" text block @click="expand(!isExpanded)">
                <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </template>
            <!-- EXPAND -->
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                {{ item.note }}
              </td>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-divider />

      <display-actions
        :display-name="displayName"
        :is-loading="isLoading"
        @exit="exit"
        @addItem="addItem"
        @refreshData="reloadData"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteDialog from './_CommonDelete.vue'
import crudDisplay from '../../mixins/crudDisplay'
import DisplayHeader from './_CommonDisplayHeader.vue'
import DisplayActions from './_CommonDisplayActions.vue'
import dateF from '../../services/date.service'

export default {
  components: {
    DeleteDialog,
    EditDialog: () => import('./driver-leave-edit.vue'),
    DisplayHeader,
    DisplayActions,
  },
  mixins: [crudDisplay],
  props: ['driverId', 'index', 'driverText', 'visible'],
  data() {
    return {
      databaseModule: 'driverLeaves',
      displayName: 'Leave',
      headers: [
        { text: 'Note', value: 'data-table-expand', displayValue: 'note' },
        { text: 'Date from', value: 'dateFrom', formatter: dateF.formatDate },
        { text: 'Date to', value: 'dateTo', formatter: dateF.formatDate },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      emitExit: false,
      expanded: [],
    }
  },
  computed: {
    date() {
      let activeDate = this.$store.getters['activeDate']
      return dateF.selectedDay(activeDate, 0)
    },
    // formattedDate() {
    //   return dateF.formatDate(this.date)
    // },
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
    fetchParams() {
      return {
        date: this.date.toISOString(),
        driverId: this.driverId,
      }
    },
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        this.selectedItem.driverId = this.driverId
        if (!value && this.emitExit) {
          this.emitExit = false
          this.$emit('closeLeave', this.selectedItem)
        }
      },
    },
  },
  methods: {
    exit() {
      this.emitExit = true
      this.dialog = false
      this.reload = true
    },
    formattedDate(date) {
      return dateF.formatDate(date)
    },
  },
}
</script>
<style scoped>
@import '../../assets/styles/crudDialog.css';
</style>
