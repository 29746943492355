<template>
  <common-display
    :edit-path="editDialog"
    :max-width="maxWidth"
    :database-module="databaseModule"
    :display-name="displayName"
    :headers="headers"
    @closeDialog="displayDialog = false"
  >
  </common-display>
</template>

<script>
import CommonDisplay from './_CommonDisplay.vue'

export default {
  components: {
    CommonDisplay,
  },
  data() {
    return {
      /*****  change after copying *****/
      databaseModule: 'boards',
      displayName: 'Board',
      editDialog: 'BoardEdit.vue',
      maxWidth: '600px',
      headers: [
        { text: 'Board', value: 'boardName' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      /*******************************************/
    }
  },
}
</script>
