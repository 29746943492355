<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" persistent>
    <template>
      <delete-dialog
        :visible="deleteDialog"
        :headers="headersWithoutAction"
        :selected-item="selectedItem"
        :dialog-title="'Delete ' + displayName"
        @closeDelete="closeDelete"
      />
      <component
        :is="editComponent"
        :visible="editDialog"
        :selected-item="selectedItem"
        :dialog-title="dialogText"
        :database-module="databaseModule"
        :edit-params="editParams"
        @closeEdit="closeEdit"
      />
    </template>
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope">
        <v-btn block color="info" text v-bind="scope.attrs" v-on="scope.on">
          {{ displayName }}
        </v-btn>
      </slot>
    </template>
    <v-card>
      <display-header :display-name="displayName" @onSearch="onSearch" />

      <v-card-text class="pb-0">
        <v-container>
          <v-data-table
            :headers="headers"
            :items="allItems"
            :search="search"
            :loading="isLoading"
            loading-text="Loading... Please wait!"
            :show-expand="showExpanded"
            expanded.sync="expanded"
          >
            <!-- Pass all slots except defined slots to a data-table -->
            <template v-for="(_, slot) of filteredSlots" v-slot:[slot]="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
            <!--  -->

            <!-- Display action buttons in table -->
            <template v-slot:item.actions="scope">
              <slot name="item.actions" v-bind="scope">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small class="mr-2" v-bind="attrs" color="success" @click="updateItem(scope.item)" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit item</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      color="error"
                      :disabled="!(isAdmin || enableEdit)"
                      @click="deleteItem(scope.item)"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete item</span>
                </v-tooltip>
                <slot name="addedActions" v-bind="scope"></slot>
              </slot>
            </template>
            <!--  -->
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-divider />

      <display-actions
        :display-name="displayName"
        :is-loading="isLoading"
        :disabled-add="!(isAdmin || enableEdit)"
        @exit="exit"
        @addItem="addItem"
        @refreshData="reloadData"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteDialog from './_CommonDelete.vue'
import crudDisplay from '../../mixins/crudDisplay'
import DisplayHeader from './_CommonDisplayHeader.vue'
import DisplayActions from './_CommonDisplayActions.vue'

export default {
  components: {
    DeleteDialog,
    DisplayHeader,
    DisplayActions,
  },
  mixins: [crudDisplay],
  props: [
    'visible',
    'maxWidth',
    'editPath',
    'databaseModule',
    'displayName',
    'headers',
    'fetchParams',
    'editParams',
    'enableEdit',
    'showExpanded',
  ],
  data() {
    return {
      dialog: false,
      expanded: [],
    }
  },
  computed: {
    editComponent() {
      return () => import('@/components/CRUD Dialog/' + this.editPath)
    },
    filteredSlots() {
      let slots = {}
      let definedSlots = ['item.actions', 'activator', 'addedActions']
      for (let key in this.$scopedSlots) {
        if (!definedSlots.includes(key)) {
          slots[key] = this.$scopedSlots[key]
        }
      }
      return slots
    },
  },
}
</script>
<style scoped>
@import '../../assets/styles/crudDialog.css';
</style>
