<template>
  <div>
    <contact-dialog
      :driver-id="selectedDriverId"
      :driver-text="selectedDriverText"
      :visible="driverContactDialog"
      @closeContact="closeContact"
    />

    <leave-dialog
      :driver-id="selectedDriverId"
      :driver-text="selectedDriverText"
      :visible="driverLeaveDialog"
      @closeLeave="closeLeave"
    />

    <hos-dialog
      :driver-id="selectedDriverId"
      :driver-text="selectedDriverText"
      :index="rightIndex"
      :visible="driverHosDialog"
      @closeContact="closeHos"
    />

    <edit-dialog
      :index="editIndex"
      :selected-item="editItem"
      :visible="editDialog"
      :field="editField"
      :title="editTitle"
      @closeEditDialog="closeEditDialog"
    />

    <truck-edit
      :database-module="truckModule"
      :selected-item="selectedTruck"
      :visible="truckEdit"
      dialog-title="Update truck"
      @closeEdit="closeTruckEdit"
    />

    <trailer-edit
      :database-module="trailerModule"
      :selected-item="selectedTrailer"
      :visible="trailerEdit"
      dialog-title="Update trailer"
      @closeEdit="closeTrailerEdit"
    />

    <!-- Popup menu za text polja na desni klik -->
    <template>
      <v-menu v-model="showMenu" top offset-x absolute :position-x="menuX" :position-y="menuY" close-on-click>
        <v-sheet class="pa-2">
          <v-btn block color="info" text class="d-flex d-lg-none" @click="showTrackingLog"> Tracking Log </v-btn>
          <v-divider class="d-flex d-lg-none"></v-divider>
          <v-btn block color="info" text :disabled="!isEditor" @click="updateText"> Update Text </v-btn>
          <v-btn block color="info" text :disabled="!isEditor" @click="updateDashboardNote"> Update Note </v-btn>
          <v-btn block color="info" text :disabled="!isEditor" @click="updateHos"> Update HoS </v-btn>
          <v-divider />
          <v-menu open-on-hover right offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="info" block text :disabled="!isEditor" v-bind="attrs" v-on="on">
                Change status
                <v-icon right>mdi-menu-right</v-icon>
              </v-btn>
            </template>

            <v-sheet class="pa-2">
              <v-btn block color="error" text @click="updateColor('error')"> Empty needs a load </v-btn>
              <v-btn block color="info" text @click="updateColor('info')"> Problem </v-btn>
              <v-btn block text @click="updateColor('')"> Default </v-btn>
              <v-divider></v-divider>
              <v-btn block color="warning" text @click="updateCovered()">
                {{ textIsCovered(rightItem, rightIndex) ? 'Uncovered' : 'Covered' }}
              </v-btn>
            </v-sheet>
          </v-menu>
          <!-- <v-btn block color="info" text>Change color</v-btn> -->
        </v-sheet>
      </v-menu>
    </template>
    <!--  -->

    <v-row class="d-flex d-md-none">
      <v-col cols="12" class="d-flex d-sm-none">
        <ActiveDate />
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="auto">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          group-by="dispatcherId"
          :search="search"
          class="borderDataTable"
          :items-per-page="itemsPerPage"
          hide-default-footer
          expanded.sync="expanded"
          show-expand
          fixed-header
          :loading="isLoading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Coloring trucks -->
          <template v-slot:item.truckId="{ item }">
            <v-chip :color="item.truckColor" outlined @click="truckInfo(item.truckId)">
              {{ item.truckId }}
            </v-chip>
          </template>
          <!--  -->

          <!-- Coloring trailers -->
          <template v-slot:item.trailerId="{ item }">
            <v-chip :color="item.trailerColor" outlined @click="trailerInfo(item.trailerId)">
              {{ item.trailerId }}
              <v-icon v-if="item.smallTrailer" right class="ml-0">mdi-exclamation</v-icon>
            </v-chip>
          </template>
          <!--  -->

          <!-- Za Group Header -->
          <template v-slot:group.header="{ headers, items, isOpen, toggle }">
            <th :colspan="headers.length" class="secondary headerText">
              <v-icon dark @click="toggle">
                {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              {{ items[0].dispatcherText }}
            </th>
          </template>
          <!--  -->

          <!-- ZA TEXT -->
          <template v-for="(n, index) in 7" v-slot:[textSlot(index)]="{ item }">
            <v-card
              :key="item.id + '-text' + index"
              height="100%"
              :class="[
                'justify-center',
                'd-flex',
                'align-content-center',
                'flex-wrap',
                'pa-1',
                textSelectedClass(item, index),
                'selection-prevent',
                'mwCell',
              ]"
              min-width="50px"
              min-height="50px"
              rounded="0"
              flat
              @click="selectedText(item, index)"
              @contextmenu="show($event, item, index)"
            >
              <v-icon v-if="textIsCovered(item, index)" color="warning">mdi-check-circle</v-icon>
              <v-icon v-if="textIsVacation(item, index)" color="error">mdi-minus-circle</v-icon>
              <v-card-text :class="['pa-0', textColorClass(item, index)]">
                {{ textDisplay(item, index) }}
              </v-card-text>
            </v-card>
          </template>
          <!--  -->

          <!-- ZA HOS -->
          <template v-for="(n, index) in 7" v-slot:[slotHoS(index)]="{ item }">
            <hos-cell :key="item.id + '-hos' + index" :data="displayHoS(item, index)" class="ma-1 mwCell" />
          </template>
          <!--  -->

          <!-- ZA Notes -->
          <!-- <template v-for="(n, index) in 7" v-slot:[slotNote(index)]="{ item }">
            <span :key="item.id + '-note' + index" outlined class="success--text ma-1">
              {{ displayNote(item, index) }}
            </span>
          </template> -->
          <!--  -->

          <!-- za dashboardNote -->
          <template v-slot:item.dashboardNote="{ item }">
            <span :key="item.id + '-dashboardNote'" class="success--text ma-1 mwCell">
              {{ item.dashboardNote }}
            </span>
          </template>
          <!--  -->

          <!-- BUTTONS FOR EXPAND I CONTACT -->
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <!-- Contact Info -->
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text block color="info" v-bind="attrs" @click.stop="contactInfo(item)" v-on="on">
                  <v-icon>mdi-account-box</v-icon>
                </v-btn>
              </template>
              Contact Info
            </v-tooltip>

            <!-- Vacation Info -->
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text block color="info" v-bind="attrs" @click.stop="vacationInfo(item)" v-on="on">
                  <v-icon>mdi-umbrella-beach</v-icon>
                </v-btn>
              </template>
              Vacation Info
            </v-tooltip>

            <!-- Expand button -->
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="expandButtonColor(item.driverNote)"
                  text
                  block
                  v-bind="attrs"
                  @click="expand(!isExpanded)"
                  v-on="on"
                >
                  <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>
              Driver note
            </v-tooltip>
          </template>
          <!--  -->

          <!-- for EXPANDED -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="cursor: pointer" @click="updateDriverNote(item)">
              <!-- Add/Edit button -->
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1" x-small fab outlined 
                    :color="item.driverNote? 'green' : 'info'"  v-bind="attrs" v-on="on">
                    <v-icon v-if="item.driverNote" dark>mdi-pencil</v-icon>
                    <v-icon v-else dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Update Driver Note</span>
              </v-tooltip> -->
              <p v-if="item.driverNote" class="ma-2">{{ item.driverNote }}</p>
              <span v-else class="info--text ml-2">Click to add driver note</span>
            </td>
          </template>
          <!--  -->
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ContactDialog from './CRUD Dialog/driver-contact-info-display.vue'
import LeaveDialog from './CRUD Dialog/driver-leave-display.vue'
import HosDialog from './CRUD Dialog/driver-hos-display.vue'
import ActiveDate from './ActiveDatePicker.vue'
import EditDialog from './Dashboard/Edit.vue'
import TruckEdit from './CRUD Dialog/TruckEdit.vue'
import TrailerEdit from './CRUD Dialog/TrailerEdit.vue'
import HosCell from './HosCell.vue'

import dateF from '../services/date.service'
/*
          0 - monday: '',
          1 - tuesday: '',
          2 - wednesday: '',
          3 - thursday: '',
          4 - friday: '',
          5 - saturday: '',
          6 - sunday: '',
*/
export default {
  components: {
    ContactDialog,
    LeaveDialog,
    ActiveDate,
    EditDialog,
    TruckEdit,
    TrailerEdit,
    HosDialog,
    HosCell,
  },
  data() {
    return {
      sortBy: 'truckId',
      sortDesc: false,
      colSpan: 4,
      dayHeaders: [],
      defaultHeaders: [
        { text: 'Truck#', value: 'truckId', align: 'center', divider: true, class: 'sticky-header' },
        { text: 'Trailer#', value: 'trailerId', align: 'center', divider: true, class: 'sticky-header' },
        { text: 'Driver', value: 'driverText', align: 'center', divider: true, class: 'sticky-header' },
        {
          text: '',
          value: 'data-table-expand',
          sortable: false,
          align: 'center',
          divider: true,
          class: 'sticky-header',
        },
      ],
      itemsPerPage: -1,
      showMenu: false,
      menuX: 0,
      menuY: 0,
      driverContactDialog: false,
      driverLeaveDialog: false,
      driverHosDialog: false,
      editDialog: false,
      expanded: [],
      popupGroup: null,
      editField: '',
      editTitle: '',
      selectedDriverId: '',
      selectedDriverText: '',
      editItem: '',
      editIndex: '',
      rightItem: {},
      rightIndex: '',
      truckModule: 'trucks',
      truckEdit: false,
      trailerModule: 'trailers',
      trailerEdit: false,
      /************************
       * covered - *
       * vacation - #
       ************************/
      badges: ['*', '#'],
    }
  },
  computed: {
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
    selectedTrailer() {
      let trailer = this.$store.getters[this.trailerModule + '/filtered'][0]
      if (trailer) trailer.action = 'edit'
      return trailer
    },
    selectedTruck() {
      let truck = this.$store.getters[this.truckModule + '/filtered'][0]
      if (truck) truck.action = 'edit'
      return truck
    },
    selectedId() {
      return this.selectedItem.id
    },
    tableHeaders() {
      var sum = this.defaultHeaders.concat(this.dayHeaders)
      return sum
    },
    activeDay() {
      return this.$store.getters.activeDay
    },
    activeBoard() {
      return this.$store.getters.activeBoard
    },
    isLoading() {
      return this.$store.getters.loadingDashboard
    },
    tableData() {
      return this.$store.getters.dashboard
    },
    selectedItem: {
      get() {
        return this.$store.getters.dashboardItem
      },
      set(value) {
        this.$store.commit('SET_DASHBOARD_ITEM', value)
      },
    },
    selectedIndex: {
      get() {
        return this.$store.getters.dashboardIndex
      },
      set(value) {
        this.$store.commit('SET_DASHBOARD_INDEX', value)
      },
    },
    search: {
      get() {
        return this.$store.getters.dashboardSearch
      },
      set(value) {
        this.$store.dispatch('setDashboardSearch', value)
      },
    },
  },
  watch: {
    activeDay(val) {
      this.formHeaders(val)
    },
    isLoading(val) {
      // added if week is changed but not day
      if (!val) {
        this.formHeaders(this.activeDay)
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.formHeaders(this.activeDay)
    })
  },
  methods: {
    showTrackingLog() {
      this.selectedText(this.rightItem, this.rightIndex)
      this.$emit('showTrackingLog')
    },
    formHeaders(val) {
      if (val === -1 || typeof this.$store.getters.activeDate !== 'object') {
        Object.assign(this.dayHeaders, [])
        this.colSpan = 4
        return
      }
      var tHeaders = []
      //var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      var days = []

      // var startDay = new Date(this.$store.getters.activeDate)
      // startDay.setDate(startDay.getDate() - startDay.getDay() + 1)
      // +1 zbog toga sto krece od nedelje
      let startDay = new Date(this.$store.getters.activeDate.getTime())
      startDay.setUTCDate(startDay.getUTCDate() - ((((startDay.getUTCDay() - 1) % 7) + 7) % 7))

      for (let i = 0; i < 7; i++) {
        let day = new Date(startDay)
        day.setUTCDate(day.getUTCDate() + i)
        days.push(dateF.formatDate(day))
      }
      for (var i = 0; i < 7; i++) {
        if (i === val) {
          tHeaders.push({
            text: 'HoS >>>',
            value: 'hos' + i,
            divider: true,
            align: 'center',
            class: 'sticky-header success--text',
          })
          tHeaders.push({
            text: days[i],
            value: 'text' + i,
            divider: true,
            align: 'center',
            class: 'sticky-header error--text',
          })
        } else
          tHeaders.push({ text: days[i], value: 'text' + i, divider: true, align: 'center', class: 'sticky-header' })
      }
      tHeaders.push({
        text: 'Note',
        value: 'dashboardNote',
        divider: true,
        align: 'center',
        class: 'sticky-header success--text',
      })
      this.colSpan = 13
      this.dayHeaders = tHeaders.slice()
    },
    textSlot(index) {
      return 'item.text' + index
    },
    slotHoS(index) {
      return 'item.hos' + index
    },
    // slotNote(index) {
    //   return 'item.note' + index
    // },
    textDisplay(item, index) {
      var t = 'text' + index
      return item[t]
    },
    displayHoS(item, index) {
      var t = 'hos' + index
      return item[t]
    },
    // displayNote(item, index) {
    //   var t = 'note' + index
    //   return item[t]
    // },
    trailerInfo(trailerId) {
      if (!trailerId) return
      let params = {
        id: trailerId,
        _filtered: true,
      }
      this.$store
        .dispatch(this.trailerModule + '/getItems', params)
        .then(() => {
          if (this.selectedTrailer) this.trailerEdit = true
        })
        .catch((err) => this.$store.dispatch('setError', err))
    },
    closeTrailerEdit() {
      this.trailerEdit = false
    },
    truckInfo(truckId) {
      if (!truckId) return
      let params = {
        id: truckId,
        _filtered: true,
      }
      this.$store
        .dispatch(this.truckModule + '/getItems', params)
        .then(() => {
          if (this.selectedTruck) this.truckEdit = true
        })
        .catch((err) => this.$store.dispatch('setError', err))
    },
    closeTruckEdit() {
      this.truckEdit = false
    },
    contactInfo(item) {
      //this.selectedItem = item
      this.selectedDriverId = item.driverId
      this.selectedDriverText = item.driverText
      this.driverContactDialog = true
    },
    closeContact() {
      this.driverContactDialog = false
    },
    vacationInfo(item) {
      this.selectedDriverId = item.driverId
      this.selectedDriverText = item.driverText
      this.driverLeaveDialog = true
    },
    closeLeave() {
      this.driverLeaveDialog = false
    },
    updateHos() {
      this.selectedDriverId = this.rightItem.driverId
      this.selectedDriverText = this.rightItem.driverText
      this.driverHosDialog = true
    },
    closeHos() {
      this.driverHosDialog = false
    },
    selectedText(item, index) {
      this.selectedItem = item
      this.selectedIndex = index
    },
    show(e, item, index) {
      e.preventDefault()
      //this.selectedText(item, index)
      this.rightItem = item
      this.rightIndex = index
      this.showMenu = false
      this.menuX = e.clientX
      this.menuY = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    textSelectedClass(item, index) {
      var cond = item.id === this.selectedId && this.selectedIndex === index
      return {
        noBorderCard: !cond,
        borderCard: cond,
      }
    },
    textColorClass(item, index) {
      var color = item['color' + index]

      if (!color) return ''

      this.badges.forEach((badge) => {
        color = color.replace(badge, '')
      })
      return color + '--text'
      // if (this.textIsCovered(item, index)) color = color.substring(1)
      // if (color === '') color = ''
      // else color = color + '--text'
      // return color
    },
    expandButtonColor(driverNote) {
      if (driverNote) return 'error'
      else return 'info'
    },
    textIsCovered(item, index) {
      let color = item['color' + index]
      // return color && color.slice(0, 1) === '*'
      return color && color.includes('*')
    },
    textIsVacation(item, index) {
      let color = item['color' + index]
      return color && color.includes('#')
    },
    // updateNote() {
    //   this.editField = 'note'
    //   this.editTitle = 'Update Note'
    //   this.editItem = this.rightItem
    //   this.editIndex = this.rightIndex
    //   this.editDialog = true
    // },
    updateText() {
      this.editField = 'text'
      this.editTitle = 'Update Text'
      this.editItem = this.rightItem
      this.editIndex = this.rightIndex
      this.editDialog = true
    },
    updateDriverNote(item) {
      if (!this.isEditor) return
      this.editField = 'driverNote'
      this.editTitle = 'Update Driver Note'
      this.editItem = item
      this.editIndex = ''
      this.editDialog = true
    },
    updateDashboardNote() {
      this.editField = 'dashboardNote'
      this.editTitle = 'Update Note'
      this.editItem = this.rightItem
      this.editIndex = ''
      this.editDialog = true
    },
    updateCovered() {
      var color = this.rightItem['color' + this.rightIndex]
      if (this.textIsCovered(this.rightItem, this.rightIndex)) {
        color = color.replace('*', '')
      } else {
        color = '*' + color
      }
      this.updateColorValue(color)
    },
    updateColor(color) {
      var oldColor = this.rightItem['color' + this.rightIndex]
      let badges = ''
      this.badges.forEach((badge) => {
        if (oldColor.includes(badge)) badges += badge
      })
      this.updateColorValue(badges + color)
    },
    updateColorValue(color) {
      let obj = {
        id: this.rightItem.id,
        board: this.rightItem.boardId,
        change: 'color' + this.rightIndex,
        value: color,
      }
      this.closeEditDialog(obj)
    },
    closeEditDialog(ret) {
      if (ret) {
        this.$store
          .dispatch('dashboardUpdate', ret)
          .then(() => {})
          .catch((err) => {
            this.$store.dispatch('setError', err)
          })
          .finally(() => {
            this.editDialog = false
            this.showMenu = false
          })
      } else {
        this.editDialog = false
        this.showMenu = false
      }
    },
  },
}
</script>

<style scoped>
.selection-prevent {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.noBorderCard {
  cursor: pointer;
  background: transparent !important;
  border: 1px solid rgba(255, 0, 0, 0) !important;
}
.borderCard {
  cursor: pointer;
  background: transparent !important;
  border: 1px solid black !important;
}
.borderCard.theme--dark {
  border: 1px solid rgba(255, 255, 255, 0.7) !important;
}
.borderDataTable {
  border: 1px solid #e0e0e0;
}
.borderDataTable.theme--dark {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.mwCell {
  word-break: break-word;
}

.v-data-table.theme--dark {
  background-color: var(--v-background-base);
}

.v-data-table.theme--dark /deep/ .sticky-header {
  background-color: var(--v-background-base) !important;
}

.v-data-table.theme--dark,
.v-data-table.theme--dark /deep/ .v-card,
.headerText {
  color: rgba(255, 255, 255, 0.7);
}

.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ td {
  padding: 0 !important;
}

@supports (-moz-appearance: none) {
  .v-data-table /deep/ td,
  .v-data-table /deep/ tr {
    height: 100% !important;
  }
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>
