import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","close-on-click":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.isEditor}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-grid")])],1)]}}])},[[_c('assets-allocation',{attrs:{"type":_vm.assetType,"visible":_vm.assetDialog},on:{"close":_vm.closeAsset}})],_c(VSheet,{staticClass:"pa-2"},[_c('truck-dialog'),_c('trailer-dialog'),_c('driver-dialog'),_c(VMenu,{attrs:{"open-on-hover":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"info","block":"","text":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-menu-left")]),_vm._v(" allocate ")],1)]}}])},[_c(VSheet,{staticClass:"pa-2"},[_c(VBtn,{attrs:{"block":"","color":"info","text":""},on:{"click":function($event){return _vm.activateAsset('truck')}}},[_vm._v(" Trucks ")]),_c(VBtn,{attrs:{"block":"","color":"info","text":""},on:{"click":function($event){return _vm.activateAsset('trailer')}}},[_vm._v(" Trailers ")])],1)],1),_c('board-dialog'),_c('user-dialog')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }