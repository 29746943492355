<template>
  <v-card-title class="text-h5 secondary white--text">
    {{ dialogTitle }}
  </v-card-title>
</template>

<script>
export default {
  props: ['dialogTitle'],
}
</script>

<style></style>
