<template>
  <v-menu
    v-model="currDateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="320px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        label="Active Day"
        style="max-width: 320px; min-width: 290px"
        hide-details
        prepend-inner-icon="mdi-chevron-left"
        append-icon="mdi-chevron-right"
        readonly
        v-bind="attrs"
        :disabled="isDisabled"
        @click:prepend-inner="setDate(-7)"
        @click:append="setDate(7)"
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-date-picker v-model="currDate" no-title :first-day-of-week="1" @input="currDateMenu = false"> </v-date-picker>
  </v-menu>
</template>

<script>
import dateF from '../services/date.service'

export default {
  data() {
    return {
      currDateMenu: false,
    }
  },
  computed: {
    computedDateFormatted() {
      return dateF.formatDate(this.activeDate)
    },
    activeDate() {
      return this.$store.getters.activeDate
    },
    isDisabled() {
      return this.$store.getters.loadingDashboard
    },
    currDate: {
      get() {
        if (typeof this.$store.getters.activeDate === 'object')
          // return new Date(this.$store.getters.activeDate - new Date().getTimezoneOffset() * 60000)
          return new Date(this.$store.getters.activeDate).toISOString().substr(0, 10)
        return null
      },
      set(value) {
        // console.log(`currDate = ${value}, type = ${typeof value}`)
        this.$store.dispatch('setActiveDate', value).catch((err) => this.$store.dispatch('setError', err))
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof this.$store.getters.activeDate !== 'object')
        this.currDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      // this.currDate = new Date(Date.now()).toISOString().substr(0, 10)
    })
  },
  methods: {
    setDate(interval) {
      let currDate = new Date(this.currDate)
      currDate.setUTCDate(currDate.getUTCDate() + interval)
      this.currDate = currDate.toISOString().substr(0, 10)
    },
  },
}
</script>
