import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-display',{attrs:{"edit-path":_vm.editDialog,"max-width":_vm.maxWidth,"database-module":_vm.databaseModule,"display-name":_vm.displayName,"headers":_vm.headers,"show-expanded":true},on:{"closeDialog":function($event){_vm.displayDialog = false}},scopedSlots:_vm._u([{key:"item.statusText",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.statusColor,"outlined":""}},[_vm._v(" "+_vm._s(item.statusText)+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.note)?_c(VBtn,{attrs:{"color":"error","text":"","block":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c(VIcon,[_vm._v(_vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v(" "+_vm._s(item.note)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }