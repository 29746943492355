import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('contact-dialog',{attrs:{"driver-id":_vm.driverId,"driver-text":_vm.driverFullName,"visible":_vm.contactDialog},on:{"closeContact":_vm.closeContact}}),_c('common-display',{ref:"display",attrs:{"edit-path":_vm.editDialog,"max-width":_vm.maxWidth,"database-module":_vm.databaseModule,"display-name":_vm.displayName,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDate(item.birthday))+" ")]}},{key:"addedActions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.contactInfo(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-box ")])]}}],null,true)},[_c('span',[_vm._v("Contact Info")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }