import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardActions,[(!_vm.noAddButton)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-2",attrs:{"outlined":"","fab":"","color":"primary","disabled":_vm.isLoading || _vm.disabledAdd},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3539909789)},[_c('span',[_vm._v("Add "+_vm._s(_vm.displayName))])]):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","outlined":"","fab":"","color":"primary","disabled":_vm.isLoading},on:{"click":_vm.refreshData}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" Refresh data ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.exit}},[_vm._v(" Exit ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }