import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('common-display',{ref:"display",attrs:{"edit-path":_vm.editDialog,"max-width":_vm.maxWidth,"database-module":_vm.databaseModule,"display-name":_vm.displayName,"headers":_vm.headers,"fetch-params":_vm.fetchParams,"edit-params":_vm.editParams,"enable-edit":true,"show-expanded":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"info","outlined":"","dark":"","disabled":!_vm.isEditor}},'v-btn',attrs,false),on),[_vm._v(" Add New Load Info ")])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"info--text subtitle-1"},[_vm._v(" Truck#"),_c('strong',[_vm._v(_vm._s(_vm.selectedTruck))]),_vm._v(", Driver: "),_c('strong',[_vm._v(_vm._s(_vm.selectedDriver))]),_vm._v(", Date: "),_c('strong',[_vm._v(_vm._s(_vm.selectedDate))])])]},proxy:true},{key:"item.dateFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatFullDate(item.dateFrom))+" ")]}},{key:"item.dateTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatFullDate(item.dateTo))+" ")]}},{key:"item.loadTypeText",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.loadTypeColor,"outlined":""}},[_vm._v(" "+_vm._s(item.loadTypeText)+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.note)?_c(VBtn,{attrs:{"color":"error","text":"","block":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c(VIcon,[_vm._v(_vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v(" "+_vm._s(item.note)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }