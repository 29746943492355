<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <template>
      <edit-dialog
        :visible="editDialog"
        :selected-item="selectedItem"
        :formatted-date="formattedDate"
        :dialog-title="dialogText"
        :database-module="databaseModule"
        @closeEdit="closeEdit"
      />
    </template>
    <v-card>
      <display-header :display-name="displayName" @onSearch="onSearch" />

      <v-card-text class="pb-0">
        <v-container>
          <v-data-table
            :headers="headers"
            :items="allItems"
            :search="search"
            :loading="isLoading"
            loading-text="Loading... Please wait!"
            :hide-default-footer="true"
          >
            <!-- Top table caption -->
            <template v-slot:top>
              <div class="subtitle-1">
                for driver: <strong>{{ driverText }}</strong
                >, date: <strong>{{ formattedDate }}</strong>
              </div>
            </template>
            <!--  -->

            <!-- Message display -->
            <template v-slot:item.message="{ item }">
              <div style="max-width: 990px">
                <hos-cell :data="item" />
              </div>
            </template>
            <!--  -->

            <!-- Display action buttons in table -->
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="ml-2"
                    v-bind="attrs"
                    color="success"
                    :disabled="!isEditor"
                    @click="updateItem(item)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit item</span>
              </v-tooltip>
            </template>
            <!--  -->
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-divider />

      <display-actions
        :display-name="displayName"
        :is-loading="isLoading"
        :no-add-button="true"
        @exit="exit"
        @addItem="addItem"
        @refreshData="reloadData"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import crudDisplay from '../../mixins/crudDisplay'
import DisplayHeader from './_CommonDisplayHeader.vue'
import DisplayActions from './_CommonDisplayActions.vue'
import dateF from '../../services/date.service'
import HosCell from '../HosCell.vue'

export default {
  components: {
    EditDialog: () => import('./driver-hos-edit.vue'),
    DisplayHeader,
    DisplayActions,
    HosCell,
  },
  mixins: [crudDisplay],
  props: ['driverId', 'index', 'driverText', 'visible'],
  data() {
    return {
      databaseModule: 'driverHos',
      displayName: 'HoS',
      headers: [
        { text: 'Time', value: 'time' },
        { text: 'Message', value: 'message' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      emitExit: false,
    }
  },
  computed: {
    date() {
      let activeDate = this.$store.getters['activeDate']
      return dateF.selectedDay(activeDate, this.index)
    },
    formattedDate() {
      return dateF.formatDate(this.date)
    },
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
    fetchParams() {
      return {
        date: this.date.toISOString(),
        driverId: this.driverId,
      }
    },
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        this.selectedItem.driverId = this.driverId
        if (!value && this.emitExit) {
          this.emitExit = false
          this.$emit('closeContact', this.selectedItem)
        }
      },
    },
  },
  methods: {
    exit() {
      this.emitExit = true
      this.dialog = false
      this.reload = true
    },
  },
}
</script>
<style scoped>
@import '../../assets/styles/crudDialog.css';
.v-data-table /deep/ td {
  padding: 0 !important;
}
</style>
