export default {
  data() {
    return {
      selectedItem: {},
      search: '',
      deleteDialog: false,
      editDialog: false,
      dialogText: '',
      isLoading: false,
      reload: true,
    }
  },
  computed: {
    headersWithoutAction() {
      return this.headers.filter((elem) => elem.value !== 'actions')
    },
    allItems() {
      return this.$store.getters[this.databaseModule + '/' + this.databaseModule]
    },
    itemLength() {
      return this.allItems?.length
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
  },
  methods: {
    addItem() {
      this.selectedItem = { action: 'add' }
      this.dialog = false
      this.dialogText = 'Add New ' + this.displayName
      this.editDialog = true
    },
    deleteItem(item) {
      this.itemAction(item, 'delete')
      this.deleteDialog = true
    },
    updateItem(item) {
      this.itemAction(item, 'edit')
      this.dialogText = 'Update ' + this.displayName
      this.editDialog = true
    },
    itemAction(item, action) {
      var itemIndex = this.allItems.indexOf(item)

      this.selectedItem = { ...item, action: action, selectedIndex: itemIndex }
      this.dialog = false
    },
    exit() {
      this.dialog = false
      this.reload = true
    },
    closeDelete(ret) {
      if (ret) {
        this.$store
          .dispatch(this.databaseModule + '/deleteItem', this.selectedItem)
          .then(() => {
            this.selectedItem = {}
          })
          .catch((err) => this.$store.dispatch('setError', err))
          .finally(() => {
            this.dialog = true
            this.deleteDialog = false
          })
      } else {
        this.dialog = true
        this.deleteDialog = false
      }
    },
    closeEdit() {
      this.dialog = true
      this.editDialog = false
    },
    reloadData() {
      if (this.isLoading) return
      this.isLoading = true

      let params = this.fetchParams ? this.fetchParams : {}

      // Lazily load input items
      this.$store
        .dispatch(this.databaseModule + '/getItems', params)
        .then(() => {})
        .catch((err) => {
          this.$store.dispatch('setError', err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onSearch(value) {
      this.search = value
    },
  },
  watch: {
    dialog(val) {
      if (val && this.reload) {
        this.reload = false
        this.reloadData()
      }
    },
  },
}
