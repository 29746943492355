<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="secondary text-h5 white--text">Dashboard table info</v-card-title>
      <v-card-text>
        <p class="mt-4">Select <strong>Active Day</strong> to display HoS and Notes for that day.</p>
        <p>Click on <v-icon color="info">mdi-account-box</v-icon> to display driver contacts.</p>
        <p>Click on <v-icon color="info">mdi-umbrella-beach</v-icon> to display driver vacation info.</p>
        <p>
          Click on <v-icon color="info">mdi-chevron-down</v-icon> to display driver notes. You can edit driver note by
          clicking on it.
        </p>
        <p>Left click on cell in day columns to display driver's <strong>Tracking Log</strong> for that day.</p>
        <p>
          Right click on cell in day columns will open <strong>action menu</strong> for that day. In action menu you can
          edit cell text, note, HoS, and change status.
        </p>
        <v-divider></v-divider>
        <p>Truck colors:</p>
        <v-row class="mb-4">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-chip outlined color="info" class="mr-4 mb-2">Company owned</v-chip>
            <v-chip outlined color="error" class="mr-4 mb-2">O/O</v-chip>
            <v-chip outlined color="warning" class="mr-4 mb-2">O/O other operator</v-chip>
            <v-chip outlined color="success" class="mb-2">Leasing</v-chip>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-divider></v-divider>
        <p>Trailer colors:</p>
        <v-row class="mb-4">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-chip outlined color="info" class="mb-2 mr-4">Company owned</v-chip>
            <v-chip outlined color="error" class="mb-2">O/O</v-chip>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-divider></v-divider>
        <p>Cell status in day columns:</p>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <span class="mb-2 mr-4">Default</span>
            <span class="info--text mb-2 mr-4">Problem</span>
            <span class="error--text mb-2 mr-4">Empty needs a load</span>
            <span class="mr-4"><v-icon color="warning">mdi-check-circle</v-icon> - Covered</span>
            <span><v-icon color="error">mdi-minus-circle</v-icon> - Driver is off</span>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          href="mailto:marko.knezevic.kg@gmail.com,snikolic.imi@gmail.com?subject=Feedback%20for%20PAM%20Dispatcher%20Application"
          :disabled="!isEditor"
          target="_blank"
          color="info"
        >
          <v-icon left>mdi-message-alert</v-icon>
          Send feedback</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
  },
  methods: {
    sendFeedback() {
      window.location.href = 'mailto:demo@demo.com'
    },
  },
}
</script>
