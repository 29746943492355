<template>
  <v-dialog v-model="dialog" max-width="300px" persistent>
    <v-form ref="form">
      <v-card>
        <v-card-title class="text-h5 secondary white--text">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea v-model="itemValue" auto-grow label="Note" rows="4" row-height="30" outlined autofocus>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="exit">Cancel</v-btn>
          <v-btn color="success" text @click="submit">Save changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { jsonServer } from '../../store/dbSettings'
export default {
  props: ['visible', 'selectedItem', 'title', 'databaseModule', 'loadModule'],
  data() {
    return {
      itemValue: '',
      label: '',
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeEditDialog', this.selectedItem)
        }
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.updateInputs()
      }
    },
  },
  methods: {
    submit() {
      var obj = {
        loadInfoId: this.selectedItem.loadInfoId,
        note: this.itemValue,
        //"noteDate": "2021-11-08T08:20:00.000Z",
        userId: this.selectedItem.userId,
        userName: this.selectedItem.userName,
      }

      if (jsonServer) {
        obj.noteDate = new Date().toISOString()
      }

      var action = ''
      if (this.selectedItem.action === 'edit') {
        obj.id = this.selectedItem.id
        action = 'updateItem'
      } else if (this.selectedItem.action === 'add') {
        action = 'addItem'
      } else {
        throw 'Unexpected action in edit dialog'
      }

      this.$store
        .dispatch(this.databaseModule + '/' + action, obj)
        .then((note) => {
          //action is unchanged (add, edit)
          let call = this.selectedItem.action === 'edit' ? '/updateNotes' : '/addNotes'
          call = this.loadModule + call
          this.$store.dispatch(call, note)
          this.dialog = false
        })
        .catch((err) => {
          this.$store.dispatch('setError', err)
        })
    },
    exit() {
      this.dialog = false
    },
    updateInputs() {
      this.itemValue = this.selectedItem.note
    },
  },
}
</script>
