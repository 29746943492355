<template>
  <v-dialog v-model="dialog" max-width="300px" persistent>
    <v-form ref="form">
      <v-card>
        <v-card-title class="text-h5 secondary white--text">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model.trim="itemValue"
                  auto-grow
                  :label="label"
                  rows="4"
                  row-height="30"
                  outlined
                  autofocus
                  clearable
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="exit">Cancel</v-btn>
          <v-btn color="success" text @click="submit">Save changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: ['visible', 'selectedItem', 'index', 'title', 'field'],
  data() {
    return {
      itemValue: '',
      label: '',
      returnObject: '',
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeEditDialog', this.returnObject)
        }
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.updateInputs()
      }
    },
  },
  methods: {
    submit() {
      this.returnObject = {
        id: this.selectedItem.id,
        board: this.selectedItem.boardId,
        change: this.field + this.index,
        value: this.itemValue ? this.itemValue : '',
      }
      this.dialog = false
    },
    exit() {
      this.returnObject = ''
      this.dialog = false
    },
    updateInputs() {
      this.itemValue = this.selectedItem[this.field + this.index]
      var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      let day = days[this.index] ? days[this.index] + ', ' : ''
      this.label = day + 'Truck#' + this.selectedItem.truckId
    },
  },
}
</script>
