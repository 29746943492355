<template>
  <v-menu offset-y close-on-click close-on-content-click>
    <template>
      <assets-allocation :type="assetType" :visible="assetDialog" @close="closeAsset" />
    </template>

    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :disabled="!isEditor" v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-grid</v-icon>
      </v-btn>
    </template>
    <v-sheet class="pa-2">
      <truck-dialog />
      <trailer-dialog />
      <driver-dialog />
      <v-menu open-on-hover left offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="info" block text v-bind="attrs" v-on="on">
            <v-icon left>mdi-menu-left</v-icon>
            allocate
          </v-btn>
        </template>

        <v-sheet class="pa-2">
          <v-btn block color="info" text @click="activateAsset('truck')"> Trucks </v-btn>
          <v-btn block color="info" text @click="activateAsset('trailer')"> Trailers </v-btn>
        </v-sheet>
      </v-menu>
      <board-dialog />
      <user-dialog />
    </v-sheet>
  </v-menu>
</template>

<script>
import TruckDialog from './CRUD Dialog/TruckDisplay.vue'
import TrailerDialog from './CRUD Dialog/TrailerDisplay.vue'
import DriverDialog from './CRUD Dialog/DriverDisplay.vue'
import BoardDialog from './CRUD Dialog/BoardDisplay.vue'
import UserDialog from './CRUD Dialog/UserDisplay.vue'
import AssetsAllocation from './CRUD Dialog/AssetsAllocateDisplay.vue'

export default {
  components: {
    AssetsAllocation,
    TruckDialog,
    TrailerDialog,
    DriverDialog,
    BoardDialog,
    UserDialog,
  },
  data() {
    return {
      assetType: '',
      assetDialog: false,
    }
  },
  computed: {
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
  },
  methods: {
    activateAsset(type) {
      this.assetType = type
      this.assetDialog = true
    },
    closeAsset() {
      this.assetDialog = false
    },
  },
}
</script>

<style></style>
