<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <template>
      <delete-dialog
        :visible="deleteDialog"
        :headers="headersWithoutAction"
        :selected-item="selectedItem"
        :dialog-title="'Delete ' + displayName"
        @closeDelete="closeDelete"
      />
      <edit-dialog
        :visible="editDialog"
        :selected-item="selectedItem"
        :dialog-title="dialogText"
        :database-module="databaseModule"
        @closeEdit="closeEdit"
      />
    </template>
    <v-card>
      <display-header :display-name="displayName + ' allocation'" @onSearch="onSearch" />

      <v-card-text class="pb-0">
        <v-container>
          <v-data-table
            :headers="headers"
            :items="allItems"
            :search="search"
            :loading="isLoading"
            loading-text="Loading... Please wait!"
          >
            <!-- formatted date -->
            <template v-slot:item.date="{ item }">
              {{ formattedDate(item.date) }}
            </template>

            <!-- Coloring fields by status -->
            <template v-slot:item.status="{ item }">
              <v-chip :color="item.status === 'dropped' ? 'error' : 'success'" outlined>
                {{ item.status === 'dropped' ? 'Dropped' : 'Picked up' }}
              </v-chip>
            </template>
            <!--  -->

            <template v-slot:item.truckId="{ item }">
              <span :class="type === 'truck' ? 'info--text' : ''">{{ item.truckId }}</span>
            </template>

            <template v-slot:item.trailerId="{ item }">
              <span :class="type === 'truck' ? '' : 'info--text'">{{ item.trailerId }}</span>
            </template>

            <!-- Display action buttons in table -->
            <!-- <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" v-bind="attrs" color="success" @click="updateItem(item)" v-on="on">
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit item</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" color="error" @click="deleteItem(item)" v-on="on"> mdi-delete </v-icon>
                </template>
                <span>Delete item</span>
              </v-tooltip>
            </template> -->
            <!--  -->
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <!-- Pick up button -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              outlined
              fab
              color="success"
              v-bind="attrs"
              :disabled="isLoading || !isAdmin"
              @click.stop="addItem('picked')"
              v-on="on"
            >
              <v-icon dark> mdi-truck-check </v-icon>
            </v-btn>
          </template>
          <span>Pick up {{ displayName }}</span>
        </v-tooltip>
        <!--  -->

        <!-- Remove button -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              outlined
              fab
              color="error"
              v-bind="attrs"
              :disabled="isLoading || !isAdmin"
              @click.stop="addItem('dropped')"
              v-on="on"
            >
              <v-icon dark> mdi-truck-remove </v-icon>
            </v-btn>
          </template>
          <span>Drop {{ displayName }}</span>
        </v-tooltip>
        <!--  -->

        <!-- REFRESH BUTTON -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              icon
              outlined
              fab
              color="primary"
              v-bind="attrs"
              :disabled="isLoading"
              v-on="on"
              @click="reloadData"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          Refresh data
        </v-tooltip>
        <!--  -->

        <v-spacer />
        <v-btn color="error" text @click="exit"> Exit </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteDialog from './_CommonDelete.vue'
import crudDisplay from '../../mixins/crudDisplay'
import DisplayHeader from './_CommonDisplayHeader.vue'

import dateF from '../../services/date.service'

export default {
  components: {
    DeleteDialog,
    EditDialog: () => import('./AssetsAllocateEdit.vue'),
    DisplayHeader,
  },
  mixins: [crudDisplay],
  props: ['type', 'visible'],
  data() {
    return {
      /*****  change after copying *****/
      databaseModule: 'assetsAllocate',
      /*******************************************/
      emitExit: false,
      statusId: '',
    }
  },
  computed: {
    displayName() {
      if (this.type === 'truck') {
        return 'Truck'
      } else {
        return 'Trailer'
      }
    },
    headers() {
      // let obj = this.type === 'truck' ? { text: 'Truck#', value: 'truckId' } : { text: 'Trailer#', value: 'trailerId' }
      return [
        { text: 'Status', value: 'status' },
        { text: 'Driver', value: 'driverText' },
        // obj,
        { text: 'Truck#', value: 'truckId', class: this.type === 'truck' ? 'info--text' : '' },
        { text: 'Trailer#', value: 'trailerId', class: this.type === 'truck' ? '' : 'info--text' },
        { text: 'Date', value: 'date' },
        { text: 'Note', value: 'note' },
        // { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    fetchParams() {
      return { type: this.type }
    },
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        this.selectedItem.type = this.type
        this.selectedItem.status = this.status
        if (!value && this.emitExit) {
          this.emitExit = false
          this.$emit('close')
        }
      },
    },
  },
  methods: {
    formattedDate(dateString) {
      return dateF.formatWithoutDay(dateString)
    },
    addItem(status) {
      this.status = status
      this.selectedItem = { action: 'add' }
      this.selectedItem.type = this.type
      this.selectedItem.status = this.status
      // ne radi zato sto ga kontrolise visible
      //this.dialog = false
      let text = status === 'dropped' ? ' Drop' : ' Pick Up'
      this.dialogText = 'Add New ' + this.displayName + text
      this.editDialog = true
    },
    exit() {
      this.emitExit = true
      this.dialog = false
      this.reload = true
    },
  },
}
</script>
<style scoped>
@import '../../assets/styles/crudDialog.css';
</style>
