<template>
  <v-card-actions>
    <!-- ADD BUTTON -->
    <v-tooltip v-if="!noAddButton" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-2"
          outlined
          fab
          color="primary"
          v-bind="attrs"
          :disabled="isLoading || disabledAdd"
          @click="addItem"
          v-on="on"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>Add {{ displayName }}</span>
    </v-tooltip>
    <!--  -->

    <!-- REFRESH BUTTON -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon outlined fab color="primary" v-bind="attrs" :disabled="isLoading" v-on="on" @click="refreshData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      Refresh data
    </v-tooltip>
    <!--  -->

    <v-spacer />
    <v-btn color="error" text @click="exit"> Exit </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  props: ['displayName', 'isLoading', 'disabledAdd', 'noAddButton'],
  methods: {
    addItem() {
      this.$emit('addItem')
    },
    refreshData() {
      this.$emit('refreshData')
    },
    exit() {
      this.$emit('exit')
    },
  },
}
</script>
