import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 secondary white--text"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c(VCardText,[_c(VContainer,[_vm._v(" Are you sure you want to delete this item? "),_c(VList,_vm._l((_vm.headers),function(header){return _c(VListItem,{key:header.value},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(header.text))]),(header.displayValue)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(header.formatter ? header.formatter(_vm.selectedItem[header.displayValue]) : _vm.selectedItem[header.displayValue])+" ")]):_c(VListItemSubtitle,[_vm._v(" "+_vm._s(header.formatter ? header.formatter(_vm.selectedItem[header.value]) : _vm.selectedItem[header.value])+" ")])],1)],1)}),1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.cancelDelete}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":_vm.deleteItem}},[_vm._v(" Delete ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }