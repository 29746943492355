<template>
  <div>
    <v-app-bar ref="toolbar" v-mutate="onMutate" app color="secondary" dark>
      <div class="d-flex align-center d-lg-none">
        <v-img
          alt="PAM International"
          class="shrink mr-2"
          contain
          src="../assets/logoN.png"
          transition="scale-transition"
          width="90"
        />
      </div>

      <v-spacer />
      <div class="d-none d-md-flex">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Search Dashboard"
          single-line
          hide-details
          clearable
        />
      </div>
      <div class="d-none d-sm-flex ml-4">
        <active-date />
      </div>
      <v-btn icon>
        <v-icon v-if="isConnected" color="success">mdi-access-point-network</v-icon>
        <v-icon v-else color="error">mdi-access-point-network-off</v-icon>
      </v-btn>

      <!-- Menu with all dialogs -->
      <menu-dialogs />
      <!--  -->

      <help-dialog />

      <!-- Menu with user data -->
      <menu-user />
      <!--  -->
    </v-app-bar>

    <!-- Side bar for tracking log -->
    <tracking-log :visible="trackingLog" @closeTrackingLog="closeTrackingLog" />

    <v-row class="mx-1">
      <v-col cols="12">
        <dashboard-table @showTrackingLog="showTrackingLog" />
      </v-col>
    </v-row>

    <!-- BOTTOM NAV -->
    <v-bottom-navigation v-model="activeBoard" app fixed background-color="secondary" dark color="info">
      <v-btn v-for="board in boards" :key="board.id" :value="board.id">
        <span>{{ board.boardName }}{{ board.count ? '(' + board.count + ')' : '' }}</span>
        <v-icon>mdi-city</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <!--  -->
  </div>
</template>

<script>
import TrackingLog from '../components/TrackingLog.vue'
import DashboardTable from '../components/DashboardTable.vue'
import MenuDialogs from '../components/MenuDialogs.vue'
import MenuUser from '../components/MenuUser.vue'

import ActiveDate from '../components/ActiveDatePicker.vue'
import HelpDialog from '../components/HelpDialog.vue'

export default {
  name: 'Home',
  components: {
    MenuDialogs,
    MenuUser,
    TrackingLog,
    DashboardTable,
    ActiveDate,
    HelpDialog,
  },

  data() {
    return {
      trackingLog: null,
      //showLoadInfo: false,
      //navBar: null,
    }
  },
  computed: {
    isConnected() {
      return this.$store.getters['socket/isConnected']
    },
    boards() {
      return this.$store.getters['boards/boards']
    },
    activeUserBoard() {
      return this.$store.getters['auth/activeUser'].boardId
    },
    activeBoard: {
      get() {
        return this.$store.getters.activeBoard
      },
      set(val) {
        this.$store.dispatch('setActiveBoard', val).catch((err) => this.$store.dispatch('setError', err))
      },
    },
    search: {
      get() {
        return this.$store.getters.dashboardSearch
      },
      set(value) {
        this.$store.dispatch('setDashboardSearch', value)
      },
    },
  },
  created() {
    let value = localStorage.getItem('dark')
    if (value) this.$vuetify.theme.dark = true
  },
  mounted() {
    this.$store
      .dispatch('boards/getItems')
      .then(() => {
        if (typeof this.activeBoard !== 'number') {
          this.activeBoard = this.activeUserBoard ? this.activeUserBoard : this.boards[0].id
        }
        this.$store.dispatch('socket/createSocket')
      })
      .catch((error) => {
        this.$store.dispatch('setError', error)
      })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('socket/closeSocket')
    next()
    this.$store.dispatch('resetActiveBoard').catch((err) => {
      this.$store.dispatch('setError', err)
    })
    this.$store.dispatch('resetDashboard').catch((err) => this.$store.dispatch('setError', err))
  },
  methods: {
    showTrackingLog() {
      this.trackingLog = true
    },
    closeTrackingLog() {
      this.trackingLog = false
    },
    onMutate() {
      let height = 0
      const toolbar = this.$refs.toolbar
      if (toolbar) {
        height = `${toolbar.$el.offsetHeight}px`
      }
      document.documentElement.style.setProperty('--toolbarHeight', height)
    },
  },
}
</script>
<style scoped>
.sans-family {
  font-family: Sans, sans-serif;
}
.v-navigation-drawer /deep/ .v-navigation-drawer__content {
  overflow-y: scroll;
}
</style>
