<template>
  <v-card-title class="secondary text-h5">
    <v-row>
      <v-col cols="12" sm="3" align-self="end" class="textColor"> {{ displayName }} info </v-col>
      <v-spacer />
      <v-col cols="12" sm="auto">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark />
      </v-col>
    </v-row>
  </v-card-title>
</template>

<script>
export default {
  props: ['displayName'],
  data() {
    return {
      localSearch: '',
    }
  },
  computed: {
    search: {
      get() {
        return this.localSearch
      },
      set(val) {
        this.localSearch = val
        this.$emit('onSearch', this.localSearch)
      },
    },
  },
}
</script>
<style scoped>
.textColor {
  color: rgba(255, 255, 255, 0.7);
}
</style>
