<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="text-h5 secondary white--text"> Delete note? </v-card-title>
      <v-card-text>
        <v-container>
          <p>Are you sure you want to delete note:</p>
          <p>{{ selectedItem.note }}</p>
          <p class="success--text text-body-1">
            from <strong>{{ formattedDate(selectedItem.noteDate) }}</strong>
          </p>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="cancelDelete">Cancel</v-btn>
        <v-btn color="success" text @click="deleteItem">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dateF from '../../services/date.service'
export default {
  props: ['visible', 'selectedItem'],
  data() {
    return {
      deleted: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeDelete', this.deleted)
        }
      },
    },
  },
  methods: {
    formattedDate(date) {
      return dateF.formatFullDateWithSeconds(date)
    },
    cancelDelete() {
      this.deleted = false
      this.dialog = false
    },
    deleteItem() {
      this.deleted = true
      this.dialog = false
    },
  },
}
</script>
