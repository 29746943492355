<template>
  <div>
    <div v-if="!(data && data.typeId)"></div>
    <div v-else>
      <span v-if="data.typeId === 1" class="error--text">Ready at {{ formattedTime(data.readyTime) }} CST.</span>
      <span v-if="data.typeId === 2 || data.typeId === 4" class="error--text">
        <span v-if="data.typeId === 4" class="info--text">Split!</span> At
        {{ data.typeId === 2 ? data.time : formattedTime(data.splitTime) }} CST driver has
        {{ formattedHours(data.hasTime) }} driving hours. Shift ends at {{ formattedTime(data.hasEnds) }} CST.
      </span>
      <span v-if="data.isCycle" class="error--text">Cycle left {{ formattedHours(data.cyclesLeft) }}.</span>
      <span v-if="data.isRecap" class="info--text">
        <span v-if="data.hasRecapTmrw"> Tomorrow {{ formattedHours(data.recapTmrw) }}</span>
        <span v-else> No recap tomorrow</span>,

        <span v-if="data.hasRecapAfter">day after {{ formattedHours(data.recapAfter) }}</span>
        <span v-else>no recap day after</span>,

        <span v-if="data.hasRecapOnDay"> recap on {{ data.recapOnDay }} {{ formattedHours(data.recapDayValue) }} </span
        >.
      </span>
      <span v-if="data.isNoRecap" class="info--text"> No recap until {{ formattedDate(data.noRecapDate) }}.</span>
      <span class="success--text"> {{ data.note }}</span>
    </div>
    <!-- <div>
      {{ data }}
    </div> -->
  </div>
</template>

<script>
import dateF from '../services/date.service'

export default {
  props: ['data'],
  methods: {
    formattedTime(dateTime) {
      let time = dateTime ? dateTime.substring(11, 16) : ''
      return dateF.formatTime(time)
    },
    formattedHours(intMinutes) {
      let hours = Math.floor(intMinutes / 60)
      let minutes = intMinutes % 60
      return hours + ':' + ('0' + minutes).slice(-2) + 'h'
    },
    formattedDate(stringDate) {
      return dateF.formatDate(stringDate)
    },
  },
}
</script>
