import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('edit-dialog',{attrs:{"visible":_vm.editDialog,"selected-item":_vm.selectedItem,"formatted-date":_vm.formattedDate,"dialog-title":_vm.dialogText,"database-module":_vm.databaseModule},on:{"closeEdit":_vm.closeEdit}})],_c(VCard,[_c('display-header',{attrs:{"display-name":_vm.displayName},on:{"onSearch":_vm.onSearch}}),_c(VCardText,{staticClass:"pb-0"},[_c(VContainer,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.allItems,"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Loading... Please wait!","hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"subtitle-1"},[_vm._v(" for driver: "),_c('strong',[_vm._v(_vm._s(_vm.driverText))]),_vm._v(", date: "),_c('strong',[_vm._v(_vm._s(_vm.formattedDate))])])]},proxy:true},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"990px"}},[_c('hos-cell',{attrs:{"data":item}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","color":"success","disabled":!_vm.isEditor},on:{"click":function($event){return _vm.updateItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit item")])])]}}])})],1)],1),_c(VDivider),_c('display-actions',{attrs:{"display-name":_vm.displayName,"is-loading":_vm.isLoading,"no-add-button":true},on:{"exit":_vm.exit,"addItem":_vm.addItem,"refreshData":_vm.reloadData}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }