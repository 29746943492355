import { required } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  //props: ['visible', 'selectedItem', 'dialogTitle', 'databaseModule'],
  props: {
    visible: Boolean,
    selectedItem: Object,
    dialogTitle: String,
    databaseModule: String,
  },
  data() {
    return {
      defaultItem: {},
      item: {},
      edit: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeEdit', this.item)
        }
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.updateInputs()
      }
    },
  },
  methods: {
    submitFormatter() {},
    updateFormatter() {},
    insertFormatter() {},
    flattenObject(item) {
      let keys = Object.keys(item)
      keys.forEach((key) => {
        if (item[key]?.id) {
          let subKeys = Object.keys(item[key])
          subKeys.forEach((subKey) => {
            let capital = subKey.charAt(0).toUpperCase() + subKey.slice(1)
            item[key + capital] = item[key][subKey]
          })
          delete item[key]
        }
      })
    },
    submit() {
      var item = Object.assign({}, this.item)
      this.submitFormatter(item)
      let actionValue = this.selectedItem.action

      delete item.action
      delete item.selectedIndex
      var action = ''
      if (actionValue === 'edit') {
        action = this.databaseModule + '/updateItem'
      } else if (actionValue === 'add') {
        action = this.databaseModule + '/addItem'
      } else {
        throw 'Unexpected action in edit dialog'
      }

      // console.log(action)
      // console.log(item)

      this.$store
        .dispatch(action, item)
        .then(() => {
          this.dialog = false
        })
        .catch((err) => {
          this.$store.dispatch('setError', err)
        })
    },
    exit() {
      //this.selectedItem.action = 'exit'
      this.item.action = 'exit'
      this.dialog = false
    },
    updateInputs() {
      if (this.selectedItem.action === 'edit') {
        this.edit = true
        this.item = Object.assign({}, this.selectedItem)
        this.updateFormatter(this.item)
      } else {
        this.edit = false
        this.item = Object.assign({}, this.defaultItem)
        this.insertFormatter(this.item, this.selectedItem)
        requestAnimationFrame(() => {
          this.$refs.observer?.reset()
        })
      }
    },
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success) this.submit()
      })
    },
  },
}
