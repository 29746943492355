<template>
  <v-menu
    ref="menu"
    v-model="menuDate"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider v-if="notEmpty" v-slot="{ errors }" :name="label" rules="required">
        <v-text-field
          ref="text"
          v-model="dateFormatted"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          :disabled="disabled"
          :error-messages="errors"
          v-on="on"
        />
      </validation-provider>
      <v-text-field
        v-else
        ref="text"
        v-model="dateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        :disabled="disabled"
        v-on="on"
      />
    </template>
    <v-date-picker v-model="model" no-title :first-day-of-week="1" @change="saveDate" />
  </v-menu>
</template>

<script>
import dateF from '../../services/date.service'

import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    ValidationProvider,
  },
  props: ['date', 'label', 'disabled', 'notEmpty'],
  data() {
    return {
      menuDate: false,
      model: false,
    }
  },
  computed: {
    dateFormatted() {
      return dateF.formatWithoutDay(this.model)
    },
    // model: {
    //   get() {
    //     return this.date
    //   },
    //   set(value) {
    //     this.$emit('update:date', value)
    //   },
    // },
  },
  watch: {
    date(val) {
      if (val) {
        this.model = val
      } else {
        this.$emit(
          'update:date',
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
        )
      }
    },
  },
  mounted() {
    if (this.date) {
      this.model = this.date
    } else {
      this.$emit(
        'update:date',
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      )
    }
  },
  methods: {
    saveDate(date) {
      this.$emit('update:date', date)
      this.$refs.text.focus()
      // this.$refs.menuTime.save(date)
      // this.model = date
      this.menuDate = false
    },
  },
}
</script>

<style></style>
