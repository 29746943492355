<template>
  <v-input :error-messages="errorMessage">
    <template v-slot:default>
      <span
        v-if="label"
        style="white-space: nowrap"
        :class="errorMessage && errorMessage.length > 0 ? 'error--text' : ''"
        >{{ label }}</span
      >
      <v-select
        v-model="currHours"
        :items="hoursPicker ? fullHours : allHours"
        :error="errorMessage && errorMessage.length > 0 ? true : false"
        :disabled="disabled"
      >
        <template v-slot:selection="{ item }">
          <span class="d-flex justify-center" style="width: 100%">
            {{ item }}
          </span>
        </template>
      </v-select>
      <span>:</span>
      <v-select
        v-model="currMinutes"
        :items="allMinutes"
        :error="errorMessage && errorMessage.length > 0 ? true : false"
        :disabled="disabled"
      >
        <template v-slot:selection="{ item }">
          <span class="d-flex justify-center" style="width: 100%">
            {{ item }}
          </span>
        </template>
      </v-select>
      <span v-if="!hoursPicker">&nbsp;</span>
      <v-select
        v-if="!hoursPicker"
        v-model="currPeriod"
        :items="allPeriods"
        :error="errorMessage && errorMessage.length > 0 ? true : false"
        :disabled="disabled"
      >
        <template v-slot:selection="{ item }">
          <span class="d-flex justify-center" style="width: 100%">
            {{ item }}
          </span>
        </template>
      </v-select>
    </template>
  </v-input>
</template>

<script>
export default {
  props: [
    'value',
    'label',
    'errorMessage',
    /**********************************************************************
     * true - value integer (number of minutes), picker hours and minutes
     * false - value string (hh:mm 24 hours), picker 12 hours (hh:mm AM/PM)
     ***********************************************************************/
    'hoursPicker',
    'range',
    'disabled',
  ],
  data() {
    return {
      // allHours: [...Array(12).keys()].map((x) => (x < 9 ? '0' + (x + 1) : '' + (x + 1))),
      allHours: [...Array(12).keys()].map((x) => '' + (x + 1)),
      // fullHours: [...Array(40).keys()].map((x) => '' + x),
      // allMinutes: [...Array(12).keys()].map((x) => (x * 5 < 10 ? '0' + x * 5 : '' + x * 5)),
      allMinutes: [...Array(12).keys()].map((x) => '' + x * 5),
      allPeriods: ['AM', 'PM'],
      currPeriod: '',
      currHours: '',
      currMinutes: '',
      notInit: true,
    }
  },
  computed: {
    fullHours() {
      var range = this.range ? this.range : 40
      return [...Array(range).keys()].map((x) => '' + x)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: 'handleInput',
    },
    currPeriod(newVal) {
      this.notInit && this.from12to24(this.currHours, this.currMinutes, newVal)
    },
    currHours(newVal) {
      // this.notInit && newVal !== oldVal && this.from12to24(newVal, this.currMinutes, this.currPeriod)
      if (this.notInit) {
        if (this.hoursPicker) {
          this.from12toInt(newVal, this.currMinutes)
        } else {
          this.from12to24(newVal, this.currMinutes, this.currPeriod)
        }
      }
    },
    currMinutes(newVal) {
      // this.notInit && newVal !== oldVal && this.from12to24(this.currHours, newVal, this.currPeriod)
      if (this.notInit) {
        if (this.hoursPicker) {
          this.from12toInt(this.currHours, newVal)
        } else {
          this.from12to24(this.currHours, newVal, this.currPeriod)
        }
      }
    },
  },
  methods: {
    from12toInt(sHours, minutes) {
      if (!(sHours || minutes)) return

      let time = +sHours * 60 + +minutes
      this.$emit('input', time)
    },
    fromIntTo12(intMinutes) {
      this.currHours = '' + Math.floor(intMinutes / 60)
      this.currMinutes = '' + (intMinutes % 60)
    },
    from12to24(sHours, minutes, period) {
      if (!(sHours || minutes || period)) return
      const PM = period === 'PM'
      const hours = (+sHours % 12) + (PM ? 12 : 0)

      const str = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`

      this.$emit('input', str)
    },
    from24to12(time24) {
      const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{1,2})/).slice(1)
      const period = +sHours < 12 ? 'AM' : 'PM'
      const hours = +sHours % 12 || 12

      // this.currHours = ('0' + hours).slice(-2)
      // this.currMinutes = ('0' + minutes).slice(-2)
      this.currHours = '' + hours
      this.currMinutes = '' + +minutes
      this.currPeriod = period
    },
    handleInput(value) {
      if (!value) return
      this.notInit = false
      if (this.hoursPicker) {
        this.fromIntTo12(value)
      } else {
        this.from24to12(value)
      }
      this.notInit = true
    },
  },
}
</script>

<style scoped>
.v-selection /deep/ .v-select__selection--comma {
  width: 100%;
  justify-content: center;
}
.v-input.theme--dark /deep/ span {
  color: rgba(255, 255, 255, 0.7);
}
</style>
