<template>
  <div>
    <reset-password :visible="resetDialog" :selected-item="selectedUser" @closeReset="closeReset" />
    <common-display
      ref="display"
      :edit-path="editDialog"
      :max-width="maxWidth"
      :database-module="databaseModule"
      :display-name="displayName"
      :headers="headers"
    >
      <!-- birthday formatted -->
      <template v-slot:item.birthday="{ item }">
        {{ formattedDate(item.birthday) }}
      </template>

      <!-- Display action buttons in table -->
      <template v-slot:addedActions="{ item }">
        <!-- Password reset button-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2"
              small
              v-bind="attrs"
              :disabled="!isAdmin"
              color="info"
              @click.stop="resetPassword(item)"
              v-on="on"
            >
              mdi-lock-reset
            </v-icon>
          </template>
          <span>Reset password</span>
        </v-tooltip>
        <!--  -->
      </template>
      <!--  -->
    </common-display>
  </div>
</template>

<script>
import CommonDisplay from './_CommonDisplay.vue'
import ResetPassword from './UserResetPassword.vue'
import dateF from '../../services/date.service'

export default {
  components: {
    CommonDisplay,
    ResetPassword,
  },
  data() {
    return {
      /*****  change when copying *****/
      databaseModule: 'users',
      displayName: 'User',
      editDialog: 'UserEdit.vue',
      maxWidth: '1200px',
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Birthday', value: 'birthday', formatter: dateF.formatBirthday },
        { text: 'Phone Number', value: 'phoneNumber' },
        { text: 'E-mail', value: 'email' },
        { text: 'Role', value: 'roleName' },
        { text: 'Board', value: 'boardName' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      /*******************************************/
      resetDialog: false,
      selectedUser: {},
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
  },
  methods: {
    formattedDate(dateString) {
      return dateF.formatBirthday(dateString)
    },
    closeReset(ret) {
      if (ret) {
        this.$store
          .dispatch('users/addPassword', { id: this.selectedUser.id })
          .catch((err) => this.$store.dispatch('setError', err))
      }
      this.resetDialog = false
    },
    resetPassword(item) {
      this.selectedUser = Object.assign({}, item)
      this.resetDialog = true
    },
  },
}
</script>

<style></style>
