import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Mutate from 'vuetify/lib/directives/mutate';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{directives:[{def: Mutate,name:"mutate",rawName:"v-mutate",value:(_vm.onMutate),expression:"onMutate"}],ref:"toolbar",attrs:{"app":"","color":"secondary","dark":""}},[_c('div',{staticClass:"d-flex align-center d-lg-none"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"PAM International","contain":"","src":require("../assets/logoN.png"),"transition":"scale-transition","width":"90"}})],1),_c(VSpacer),_c('div',{staticClass:"d-none d-md-flex"},[_c(VTextField,{attrs:{"prepend-icon":"mdi-magnify","label":"Search Dashboard","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"d-none d-sm-flex ml-4"},[_c('active-date')],1),_c(VBtn,{attrs:{"icon":""}},[(_vm.isConnected)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-access-point-network")]):_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-access-point-network-off")])],1),_c('menu-dialogs'),_c('help-dialog'),_c('menu-user')],1),_c('tracking-log',{attrs:{"visible":_vm.trackingLog},on:{"closeTrackingLog":_vm.closeTrackingLog}}),_c(VRow,{staticClass:"mx-1"},[_c(VCol,{attrs:{"cols":"12"}},[_c('dashboard-table',{on:{"showTrackingLog":_vm.showTrackingLog}})],1)],1),_c(VBottomNavigation,{attrs:{"app":"","fixed":"","background-color":"secondary","dark":"","color":"info"},model:{value:(_vm.activeBoard),callback:function ($$v) {_vm.activeBoard=$$v},expression:"activeBoard"}},_vm._l((_vm.boards),function(board){return _c(VBtn,{key:board.id,attrs:{"value":board.id}},[_c('span',[_vm._v(_vm._s(board.boardName)+_vm._s(board.count ? '(' + board.count + ')' : ''))]),_c(VIcon,[_vm._v("mdi-city")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }