<template>
  <v-card-actions>
    <v-spacer />
    <v-btn text color="error" @click="exit"> Cancel </v-btn>
    <v-btn color="success" text :disabled="notAdmin && !disabledSubmit" @click="submit"> Save changes </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  props: ['disabledSubmit'],
  computed: {
    notAdmin() {
      return !this.$store.getters['auth/isAdmin']
    },
  },
  methods: {
    exit() {
      this.$emit('exit')
    },
    submit() {
      this.$emit('submit')
    },
  },
}
</script>

<style></style>
