import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[[_c('note-edit',{attrs:{"selected-item":_vm.noteItem,"title":"Add new Note","visible":_vm.noteEditDialog,"database-module":_vm.databaseModule,"load-module":_vm.loadModule},on:{"closeEditDialog":_vm.closeAddNote}}),_c('note-delete',{attrs:{"selected-item":_vm.noteItem,"visible":_vm.noteDeleteDialog},on:{"closeDelete":_vm.closeDelete}})],_c(VDivider),_c(VCardText,{staticClass:"px-0"},[_c('div',{staticClass:"font-weight-bold ml-1 mb-2"},[_vm._v("Notes")]),_vm._l((_vm.notes),function(note,index){return _c(VCard,{key:note.id,attrs:{"flat":""}},[_c(VCardText,{staticClass:"pt-0"},[_c('div',{staticClass:"font-weight-normal"},[_c(VRow,{attrs:{"justify":"center"}},[(note.userId === _vm.activeUserId || _vm.isAdmin)?_c(VCol,{attrs:{"cols":"2","align-self":"center"}},[_c(VSpeedDial,{staticClass:"d-inline",attrs:{"direction":"top","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VBtn,{attrs:{"color":"info","text":"","fab":"","x-small":""},model:{value:(_vm.btnNote[index]),callback:function ($$v) {_vm.$set(_vm.btnNote, index, $$v)},expression:"btnNote[index]"}},[(_vm.btnNote[index])?_c(VIcon,[_vm._v(" mdi-close ")]):_c(VIcon,[_vm._v(" mdi-dots-vertical ")])],1)]},proxy:true}],null,true),model:{value:(_vm.btnNote[index]),callback:function ($$v) {_vm.$set(_vm.btnNote, index, $$v)},expression:"btnNote[index]"}},[(note.userId === _vm.activeUserId)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","dark":"","fab":"","color":"green"},on:{"click":function($event){return _vm.editNote(note)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Note")])]):_vm._e(),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","dark":"","fab":"","color":"error"},on:{"click":function($event){return _vm.deleteNote(note)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Note")])])],1)],1):_c(VSpacer),_c(VCol,{attrs:{"cols":"10"}},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v(_vm._s(note.userName))])]),_c('p',{staticClass:"mb-0 caption"},[_c('i',[_vm._v(_vm._s(_vm.formattedDate(note.noteDate)))])])])],1)],1),_c('div',{staticClass:"mt-2",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(note.note))]),_c(VDivider)],1)],1)})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }