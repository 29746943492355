import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),Object.assign({}, tooltip, menu)),[_c('user-avatar',{attrs:{"username":_vm.fullName,"size":36}})],1)]}}],null,true)},[_c('p',{staticClass:"text-caption mb-0"},[_c('strong',[_vm._v("User: ")]),_vm._v(" "+_vm._s(_vm.fullName))]),_c('p',{staticClass:"text-caption mb-0"},[_c('strong',[_vm._v("Role: ")]),_vm._v(" "+_vm._s(_vm.userRole))])])]}}])},[[_c('user-edit',{attrs:{"visible":_vm.userEdit,"selected-item":_vm.activeUser,"dialog-title":"Account Settings","database-module":"users","it-is-i":true},on:{"closeEdit":_vm.closeEdit}})],_c(VSheet,[_c(VContainer,[_c(VRow,{attrs:{"justify":"center","color":"primary"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('user-avatar',{attrs:{"username":_vm.fullName,"size":84}})],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',{staticClass:"sans-family info--text"},[_vm._v(_vm._s(_vm.fullName))])])],1),_c(VRow,[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"info","block":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.switchTheme.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.switchText)+" ")])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('update-password')],1)],1),_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"color":"info","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.accountSettings.apply(null, arguments)}}},[_vm._v(" Profile ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":_vm.signOut}},[_vm._v(" Sign Out ")])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }