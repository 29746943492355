<template>
  <div v-show="show">
    <template>
      <note-edit
        :selected-item="noteItem"
        title="Add new Note"
        :visible="noteEditDialog"
        :database-module="databaseModule"
        :load-module="loadModule"
        @closeEditDialog="closeAddNote"
      />
      <note-delete :selected-item="noteItem" :visible="noteDeleteDialog" @closeDelete="closeDelete" />
    </template>
    <v-divider></v-divider>

    <v-card-text class="px-0">
      <div class="font-weight-bold ml-1 mb-2">Notes</div>

      <v-card v-for="(note, index) in notes" :key="note.id" flat>
        <v-card-text class="pt-0">
          <div class="font-weight-normal">
            <v-row justify="center">
              <v-col v-if="note.userId === activeUserId || isAdmin" cols="2" align-self="center">
                <!-- Slide buttons -->
                <v-speed-dial v-model="btnNote[index]" direction="top" transition="slide-x-transition" class="d-inline">
                  <template v-slot:activator>
                    <v-btn v-model="btnNote[index]" color="info" text fab x-small>
                      <v-icon v-if="btnNote[index]"> mdi-close </v-icon>
                      <v-icon v-else> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <v-tooltip v-if="note.userId === activeUserId" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-1" small dark fab color="green" v-bind="attrs" @click="editNote(note)" v-on="on">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Note</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small dark fab color="error" v-bind="attrs" @click="deleteNote(note)" v-on="on">
                        <v-icon dark>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Note</span>
                  </v-tooltip>
                </v-speed-dial>
              </v-col>
              <v-spacer v-else></v-spacer>
              <v-col cols="10">
                <p class="mb-0">
                  <strong>{{ note.userName }}</strong>
                </p>
                <p class="mb-0 caption">
                  <i>{{ formattedDate(note.noteDate) }}</i>
                </p>
              </v-col>
            </v-row>
          </div>
          <div class="mt-2" style="white-space: pre-wrap">{{ note.note }}</div>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import dateF from '../../services/date.service'
import NoteEdit from './NoteEdit.vue'
import NoteDelete from './NoteDelete.vue'

export default {
  components: {
    NoteEdit,
    NoteDelete,
  },
  props: ['show', 'notes', 'loadModule'],
  data() {
    return {
      databaseModule: 'loadNotes',
      btnNote: [],
      noteItem: {},
      noteEditDialog: false,
      noteDeleteDialog: false,
    }
  },
  computed: {
    activeUserId() {
      return this.$store.getters['auth/activeUser'].id
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
  },
  methods: {
    formattedDate(date) {
      return dateF.formatFullDate(date, true)
    },
    editNote(note) {
      // console.log(note)
      // let activeUser = this.$store.getters['auth/activeUser']
      // let params = {
      //   loadInfoId: note.id,
      //   userId: activeUser.id,
      //   userName: activeUser.firstName + ' ' + activeUser.lastName,
      //   action: 'edit',
      //   note: note.note,
      // }
      this.noteItem = Object.assign({}, note)
      this.noteItem.action = 'edit'
      this.noteEditDialog = true
    },
    closeAddNote() {
      this.noteEditDialog = false
    },
    deleteNote(note) {
      this.noteItem = Object.assign({}, note)
      this.noteDeleteDialog = true
    },
    closeDelete(ret) {
      if (ret) {
        this.$store
          .dispatch(this.databaseModule + '/deleteItem', this.noteItem)
          .then((note) => {
            this.noteItem = {}
            this.$store.dispatch(this.loadModule + '/deleteNotes', note)
          })
          .catch((err) => this.$store.dispatch('setError', err))
          .finally(() => {
            this.noteDeleteDialog = false
          })
      } else {
        this.noteDeleteDialog = false
      }
    },
  },
}
</script>
<style scoped>
.v-card.theme--dark {
  background-color: var(--v-background-base);
}
</style>
