<template>
  <common-display
    :edit-path="editDialog"
    :max-width="maxWidth"
    :database-module="databaseModule"
    :display-name="displayName"
    :headers="headers"
    :show-expanded="true"
    @closeDialog="displayDialog = false"
  >
    <!-- Coloring fields by status -->
    <template v-slot:item.statusText="{ item }">
      <v-chip :color="item.statusColor" outlined>
        {{ item.statusText }}
      </v-chip>
    </template>
    <!--  -->

    <!-- EXPAND BUTTON -->
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <v-btn v-if="item.note" color="error" text block @click="expand(!isExpanded)">
        <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </template>

    <!-- EXPAND -->
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length">
        {{ item.note }}
      </td>
    </template>
  </common-display>
</template>

<script>
import CommonDisplay from './_CommonDisplay.vue'

export default {
  components: {
    CommonDisplay,
  },
  data() {
    return {
      /*****  change when copying *****/
      databaseModule: 'trucks',
      displayName: 'Truck',
      editDialog: 'TruckEdit.vue',
      maxWidth: '1200px',
      headers: [
        { text: 'Note', value: 'data-table-expand', displayValue: 'note' },
        { text: 'Status', value: 'statusText' },
        { text: 'Truck#', value: 'id' },
        { text: 'Make', value: 'make' },
        { text: 'Model', value: 'model' },
        { text: 'Year', value: 'year' },
        { text: 'Color', value: 'color' },
        { text: 'VIN', value: 'vin' },
        { text: 'Plates', value: 'plates' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      /*******************************************/
    }
  },
}
</script>

<style></style>
