import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 secondary white--text"},[_vm._v(" Delete note? ")]),_c(VCardText,[_c(VContainer,[_c('p',[_vm._v("Are you sure you want to delete note:")]),_c('p',[_vm._v(_vm._s(_vm.selectedItem.note))]),_c('p',{staticClass:"success--text text-body-1"},[_vm._v(" from "),_c('strong',[_vm._v(_vm._s(_vm.formattedDate(_vm.selectedItem.noteDate)))])])])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":_vm.deleteItem}},[_vm._v("Delete")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }