<template>
  <div>
    <common-display
      ref="display"
      :edit-path="editDialog"
      :max-width="maxWidth"
      :database-module="databaseModule"
      :display-name="displayName"
      :headers="headers"
      :fetch-params="fetchParams"
      :edit-params="editParams"
      :enable-edit="true"
      :show-expanded="true"
    >
      <!-- activator button -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" outlined v-bind="attrs" dark :disabled="!isEditor" v-on="on"> Add New Load Info </v-btn>
      </template>
      <!--  -->

      <!-- Top table caption -->
      <template v-slot:top>
        <div class="info--text subtitle-1">
          Truck#<strong>{{ selectedTruck }}</strong
          >, Driver: <strong>{{ selectedDriver }}</strong
          >, Date: <strong>{{ selectedDate }}</strong>
        </div>
      </template>
      <!--  -->

      <!-- dateFrom formatting -->
      <template v-slot:item.dateFrom="{ item }">
        {{ formatFullDate(item.dateFrom) }}
      </template>

      <!-- dateTo formatting -->
      <template v-slot:item.dateTo="{ item }">
        {{ formatFullDate(item.dateTo) }}
      </template>

      <!-- Coloring fields by type -->
      <template v-slot:item.loadTypeText="{ item }">
        <v-chip :color="item.loadTypeColor" outlined>
          {{ item.loadTypeText }}
        </v-chip>
      </template>
      <!--  -->
      <!-- EXPAND BUTTON -->
      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn v-if="item.note" color="error" text block @click="expand(!isExpanded)">
          <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </template>

      <!-- EXPAND -->
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          {{ item.note }}
        </td>
      </template>
    </common-display>
  </div>
</template>

<script>
import CommonDisplay from './_CommonDisplay.vue'
import dateF from '../../services/date.service'

export default {
  components: {
    CommonDisplay,
  },
  data() {
    return {
      /*******************************************************************************************/
      databaseModule: 'loadInfo',
      displayName: 'Load',
      editDialog: 'LoadInfoEdit.vue',
      maxWidth: '1200px',
      headers: [
        { text: 'Note', value: 'data-table-expand', displayValue: 'note' },
        { text: 'Load Type', value: 'loadTypeText' },
        { text: 'Load#', value: 'loadNo' },
        { text: 'Broker', value: 'broker' },
        { text: 'Destination', value: 'address' },
        { text: 'Appt from', value: 'dateFrom', formatter: dateF.formatFullDateWithSeconds },
        { text: 'Appt to', value: 'dateTo', formatter: dateF.formatFullDateWithSeconds },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      /*******************************************************************************************/
    }
  },
  computed: {
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
    selectedTruck() {
      return this.$store.getters.dashboardItem.truckId
    },
    selectedDriver() {
      return this.$store.getters.dashboardItem.driverText
    },
    selectedDriverId() {
      return this.$store.getters.dashboardItem.driverId
    },
    selectedDate() {
      return dateF.formatDate(this.$store.getters.dashboardDay)
    },
    fetchParams() {
      // let dateFrom = new Date(this.$store.getters.dashboardDay - new Date().getTimezoneOffset() * 60000).toISOString()
      let dateFrom = this.$store.getters.dashboardDay.toISOString()
      let params = { driverId: this.selectedDriverId, dateFrom_gte: dateFrom }
      return params
    },
    editParams() {
      return {
        driverId: this.selectedDriverId,
        driverText: this.selectedDriver,
        truckId: this.selectedTruck,
      }
    },
  },
  methods: {
    formatFullDate(dateString) {
      return dateF.formatFullDate(dateString)
    },
  },
}
</script>
