<template>
  <v-navigation-drawer v-model="navBar" app width="320px" color="secondary">
    <div class="d-lg-flex align-center justify-center d-none">
      <v-img
        alt="PAM International"
        class="shrink mr-2"
        contain
        src="../assets/logoN.png"
        transition="scale-transition"
        width="280"
      />
    </div>
    <template>
      <note-edit
        :selected-item="noteItem"
        title="Add new Note"
        :visible="noteEditDialog"
        :load-module="loadModule"
        database-module="loadNotes"
        @closeEditDialog="closeAddNote"
      />
      <load-edit
        :visible="loadEditDialog"
        :selected-item="loadItem"
        dialog-title="Update Load"
        :database-module="loadModule"
        :edit-params="editParams"
        @closeEdit="closeLoadEdit"
      />
      <delete-dialog
        :visible="loadDeleteDialog"
        :headers="headersWithoutAction"
        :selected-item="loadItem"
        dialog-title="Delete Load"
        @closeDelete="closeDelete"
      />
      <v-dialog v-model="isLoading" persistent width="320">
        <v-card color="primary" dark>
          <v-card-text class="text-h6">
            Loading data. Please wait ...
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <div v-if="selectedDate">
      <v-divider dark></v-divider>
      <v-card flat class="mb-2" color="secondary" dark>
        <v-card-title>Tracking Log</v-card-title>
        <v-card-subtitle>
          Truck#{{ selectedTruck }} <br />
          {{ selectedDate }}
        </v-card-subtitle>
        <v-card-actions><v-spacer /><LoadInfo ref="loadInfo" /><v-spacer /></v-card-actions>
      </v-card>
      <v-btn color="error" dark absolute top right fab small class="mt-6 d-flex d-lg-none" @click="navBar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <!-- Inner cards -->
      <div class="ma-1">
        <v-card v-for="(loadInfo, loadIndex) in loadsInfo" :key="loadInfo.id" class="mb-2" max-width="320px">
          <v-card-text :class="loadInfo.loadTypeColor + '--text'">
            <div>{{ loadInfo.broker + '#' + loadInfo.loadNo }}</div>
            <div class="text-capitalize">
              <strong>{{ loadInfo.loadTypeText + ': ' }}</strong>
              {{ loadInfo.address }}
            </div>
            <div>
              <strong>{{ loadInfo.range ? 'Appt from:' : 'Appt:' }}</strong> {{ formattedDate(loadInfo.dateFrom) }}
            </div>
            <div v-if="loadInfo.range"><strong>Appt to:</strong> {{ formattedDate(loadInfo.dateTo) }}</div>
            <div v-if="loadInfo.note"><strong>Note: </strong>{{ loadInfo.note }}</div>
            <div v-if="loadInfo.userFullName"><strong>by: </strong>{{ loadInfo.userFullName }}</div>
          </v-card-text>
          <v-card-actions>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  outlined
                  fab
                  color="info"
                  v-bind="attrs"
                  :disabled="!isEditor"
                  @click="addNote(loadInfo)"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Add New Note</span>
            </v-tooltip>

            <!-- Show notes button -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!-- ne sme jedna promenljiva showNotes -->
                <v-btn
                  class="mx-1"
                  x-small
                  outlined
                  fab
                  color="info"
                  v-bind="attrs"
                  @click="showN(loadIndex)"
                  v-on="on"
                >
                  <v-icon dark>
                    {{ showNotes[loadIndex] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Show Notes</span>
            </v-tooltip>
            <!--  -->

            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-1"
                  x-small
                  outlined
                  fab
                  color="info"
                  v-bind="attrs"
                  :disabled="!isEditor"
                  @click="appendLoadInfo(loadInfo)"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus-circle-multiple </v-icon>
                </v-btn>
              </template>
              <span>Append Load Info</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-1"
                  x-small
                  outlined
                  fab
                  color="success"
                  v-bind="attrs"
                  :disabled="!isEditor"
                  @click="editLoadInfo(loadInfo)"
                  v-on="on"
                >
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Edit Load Info</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  outlined
                  fab
                  color="error"
                  v-bind="attrs"
                  :disabled="!isEditor"
                  @click="deleteLoadInfo(loadInfo)"
                  v-on="on"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Delete Load Info</span>
            </v-tooltip>
          </v-card-actions>
          <v-expand-transition>
            <note-display :notes="loadInfo.loadNotes" :show="showNotes[loadIndex]" :load-module="loadModule" />
          </v-expand-transition>
        </v-card>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import NoteDisplay from './TrackingLog/NoteDisplay.vue'
import NoteEdit from './TrackingLog/NoteEdit.vue'
import dateF from '../services/date.service'
import LoadInfo from './CRUD Dialog/LoadInfoDisplay.vue'
import LoadEdit from './CRUD Dialog/LoadInfoEdit.vue'
import DeleteDialog from '../components/CRUD Dialog/_CommonDelete.vue'

export default {
  components: {
    NoteDisplay,
    NoteEdit,
    LoadInfo,
    LoadEdit,
    DeleteDialog,
  },
  props: ['visible'],
  data() {
    return {
      showNotes: [],
      //loadsInfo: [],
      //navBar: null,
      // isLoading: false,
      noteEditDialog: false,
      noteItem: {},
      loadModule: 'loadInfo',
      loadEditDialog: false,
      loadItem: {},
      loadDeleteDialog: false,
      headersWithoutAction: [],
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['loadInfo/isLoading']
    },
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
    navBar: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeTrackingLog')
        }
      },
    },
    loadsInfo() {
      return this.$store.getters['loadInfo/trackingLog']
    },
    selectedTruck() {
      var item = this.$store.getters.dashboardItem
      if (typeof item === 'object') return item.truckId
      return ''
    },
    selectedDriverId() {
      return this.$store.getters.dashboardItem.driverId
    },
    selectedDriver() {
      return this.$store.getters.dashboardItem.driverText
    },
    editParams() {
      return {
        driverId: this.selectedDriverId,
        driverText: this.selectedDriver,
        truckId: this.selectedTruck,
      }
    },
    selectedDate() {
      var day = this.$store.getters.dashboardDay
      return dateF.formatDate(day)
    },
    selectedChange() {
      /*
      item = this.$store.getters.dashboardDay
      if (typeof item === 'string') return ''

      // da bi ispisao kao treba :)
      let date = new Date(item - new Date().getTimezoneOffset() * 60000).toISOString()

      let item = this.$store.getters.dashboardItem
      let driverId = ''
      if (typeof item === 'object') driverId = item.driverId
      return { dateFrom: date, driverId: driverId }
      */

      let item = this.$store.getters.dashboardDay
      // Ne okida dogadjaj ako se definise da vrati prazno
      if (typeof item === 'string') {
        return ''
      }
      // Must change because time offset
      //let dateFrom = new Date(item - new Date().getTimezoneOffset() * 60000).toISOString()
      let dateFrom = item.toISOString()
      return { dateFrom: dateFrom, driverId: this.$store.getters.dashboardItem?.driverId }
    },
  },
  watch: {
    selectedChange(newVal) {
      if (newVal) {
        // if (oldVal) {
        //   let oldRoom = oldVal.driverId + '-' + oldVal.dateFrom.substr(0, 10)
        //   this.$store.dispatch('socket/leaveRoom', 'tracking-' + oldRoom)
        // }
        let newRoom = newVal.driverId + '-' + newVal.dateFrom.substr(0, 10)
        this.$store.dispatch('socket/joinTrackingRoom', newRoom)
        this.reloadData(newVal)
      }
    },
  },
  methods: {
    formattedDate(date) {
      return dateF.formatFullDate(date)
    },
    addNote(loadInfo) {
      let activeUser = this.$store.getters['auth/activeUser']
      let params = {
        loadInfoId: loadInfo.id,
        userId: activeUser.id,
        userName: activeUser.firstName + ' ' + activeUser.lastName,
        action: 'add',
      }
      this.noteItem = Object.assign({}, params)
      this.noteEditDialog = true
    },
    closeAddNote() {
      this.noteEditDialog = false
    },
    appendLoadInfo(loadInfo) {
      this.loadItem = Object.assign({}, loadInfo)
      delete this.loadItem.loadNotes
      this.loadItem.action = 'append'
      this.loadEditDialog = true
    },
    editLoadInfo(loadInfo) {
      this.loadItem = Object.assign({}, loadInfo)
      delete this.loadItem.loadNotes
      this.loadItem.action = 'edit'
      this.loadEditDialog = true
    },
    closeLoadEdit() {
      this.loadEditDialog = false
    },
    deleteLoadInfo(loadInfo) {
      this.headersWithoutAction = this.$refs.loadInfo?.headersWithoutAction
      this.loadItem = loadInfo
      this.loadDeleteDialog = true
    },
    closeDelete(ret) {
      if (ret) {
        this.$store
          .dispatch(this.loadModule + '/deleteItem', this.loadItem)
          .then(() => {
            this.loadItem = {}
          })
          .catch((err) => this.$store.dispatch('setError', err))
          .finally(() => {
            this.loadDeleteDialog = false
          })
      } else {
        this.loadDeleteDialog = false
      }
    },
    showN(index) {
      var val = !this.showNotes[index]
      var arr = this.showNotes
      arr[index] = val
      this.showNotes = arr.slice()
    },
    reloadData(params) {
      // if (this.isLoading) return
      // this.isLoading = true
      //this.loadsInfo = []

      // Lazily load input items
      this.$store
        .dispatch('loadInfo/getTrackingLog', params)
        .then(() => {
          //this.loadsInfo = response.slice()
        })
        .catch((err) => {
          this.$store.dispatch('setError', err)
        })
      // .finally(() => {
      //   this.isLoading = false
      // })
    },
  },
}
</script>
<style scoped>
.v-card.theme--dark {
  background-color: var(--v-background-base);
}
</style>
