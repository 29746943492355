<template>
  <v-menu offset-y :close-on-content-click="false">
    <template>
      <user-edit
        :visible="userEdit"
        :selected-item="activeUser"
        dialog-title="Account Settings"
        database-module="users"
        :it-is-i="true"
        @closeEdit="closeEdit"
      />
    </template>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <div v-bind="attrs" v-on="{ ...tooltip, ...menu }">
            <user-avatar :username="fullName" :size="36" />
          </div>
        </template>
        <p class="text-caption mb-0"><strong>User: </strong> {{ fullName }}</p>
        <p class="text-caption mb-0"><strong>Role: </strong> {{ userRole }}</p>
      </v-tooltip>
    </template>
    <v-sheet>
      <v-container>
        <v-row justify="center" color="primary">
          <v-col cols="auto">
            <user-avatar :username="fullName" :size="84" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <span class="sans-family info--text">{{ fullName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-btn color="info" block outlined @click.stop="switchTheme"> {{ switchText }} </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <update-password />
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="6">
            <v-btn color="info" block @click.stop="accountSettings"> Profile </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="info" outlined @click="signOut"> Sign Out </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-menu>
</template>

<script>
import UserAvatar from 'vue-avatar'
import UpdatePassword from './CRUD Dialog/UserUpdatePassword.vue'
import UserEdit from './CRUD Dialog/UserEdit.vue'

export default {
  components: {
    UserAvatar,
    UpdatePassword,
    UserEdit,
  },
  data() {
    return {
      userEdit: false,
      activeUser: {},
    }
  },
  computed: {
    fullName() {
      var user = this.$store.getters['auth/activeUser']
      /*
        let plus = (user.firstName.charCodeAt(0) + user.lastName.charCodeAt(0)) % 18
        let add = ''
        for (let i = 0; i < plus; i++)
          add = add + ' '
        */
      return user.firstName + ' ' + user.lastName
    },
    userRole() {
      var user = this.$store.getters['auth/activeUser']
      return user.roleName
    },
    switchText() {
      return this.$vuetify.theme.dark ? 'Light Theme' : 'Dark Theme'
    },
  },
  methods: {
    accountSettings() {
      var user = Object.assign({}, this.$store.getters['auth/activeUser'])
      user.action = 'edit'
      this.activeUser = user
      this.userEdit = true
    },
    closeEdit(item) {
      delete item.action
      delete item.selectedIndex
      this.$store.commit('auth/SET_ACTIVE', item)
      this.userEdit = false
    },
    signOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      if (this.$vuetify.theme.dark) {
        localStorage.setItem('dark', true)
      } else {
        localStorage.removeItem('dark')
      }
    },
  },
}
</script>

<style></style>
