<template>
  <div>
    <validation-provider v-if="notEmpty" v-slot="{ errors }" :name="label" rules="required">
      <v-select
        v-model="model"
        :items="allItems"
        :item-text="itemText"
        :item-value="itemValue"
        :loading="isLoading"
        :label="label"
        :disabled="disabled"
        append-outer-icon="mdi-refresh"
        :error-messages="errors"
        @click:append-outer="reloadItems(true)"
        @change="change"
      />
    </validation-provider>
    <v-select
      v-else
      v-model="model"
      :items="allItems"
      :item-text="itemText"
      :item-value="itemValue"
      :loading="isLoading"
      :label="label"
      :disabled="disabled"
      append-outer-icon="mdi-refresh"
      clearable
      @click:append-outer="reloadItems(true)"
      @change="change"
    />
  </div>
</template>

<script>
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    ValidationProvider,
  },
  props: [
    'getter',
    'databaseModule',
    'itemText',
    'itemValue',
    'data',
    'label',
    'disabled',
    'alwaysReload',
    'filterParams',
    'initialize',
    'notEmpty',
  ],
  data() {
    return {
      isLoading: false,
      needReload: false,
    }
  },
  computed: {
    allItems() {
      return this.$store.getters[this.databaseModule + '/' + this.getter]
    },
    model: {
      get() {
        return this.data
      },
      set(value) {
        // console.log('model data ' + value)
        // if (value) {
        //   this.$emit('update:data', value)
        // }
        this.$emit('update:data', value)
      },
    },
  },
  watch: {
    model() {
      if (this.needReload) {
        this.reloadItems(this.alwaysReload)
      } else {
        this.needReload = true
      }
    },
    filterParams() {
      this.reloadItems(true)
    },
  },
  mounted() {
    this.reloadItems(this.alwaysReload)
  },
  methods: {
    change() {
      this.needReload = false
      this.$emit('change')
    },
    reloadItems(forceReload) {
      if (this.isLoading) return
      this.isLoading = true
      let id = this.itemId ? this.itemId : 'id'
      this.needReload = false

      if (!this.allItems?.length || forceReload) {
        let filter = this.filterParams ? this.filterParams : {}
        let filtered = this.filterParams ? true : false
        filter._filtered = filtered
        this.$store
          .dispatch(this.databaseModule + '/getItems', filter)
          .then(() => {
            // TODO: uprostiti uslove
            // this.model = empty kada se radi add
            // kada se radi edit this.model ima vrednost
            // dodatno this.model ima vrednost kada se radi i reload u assetsAllocate
            if (this.model) {
              let obj = this.allItems.find((elem) => {
                return elem[id] === this.data
              })
              if (obj) {
                this.model = obj[id]
              } else if (this.initialize && this.allItems.length) {
                this.model = this.allItems[0].id
              }
            } else if (this.initialize && this.allItems.length) {
              this.model = this.allItems[0].id
            }
          })
          .catch((err) => {
            this.$store.dispatch('setError', err)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        if (this.model) {
          let obj = this.allItems.find((elem) => {
            return elem[id] === this.data
          })
          if (obj) {
            this.model = obj[id]
          } else if (this.initialize && this.allItems.length) {
            this.model = this.allItems[0].id
          }
        } else if (this.initialize && this.allItems.length) {
          this.model = this.allItems[0].id
        }
        this.isLoading = false
      }
    },
  },
}
</script>

<style></style>
