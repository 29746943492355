<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="info" block class="mt-2" v-bind="attrs" v-on="on"> Change password </v-btn>
    </template>
    <v-form ref="form">
      <v-card>
        <v-card-title class="text-h5 secondary white--text"> Change password </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="inputPassword"
                  label="Type new password"
                  hint="At least 8 characters"
                  counter
                  prepend-icon="mdi-lock"
                  @click:append="show1 = !show1"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="retype"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="inputRetype"
                  label="Retype password"
                  counter
                  prepend-icon="mdi-lock"
                  @click:append="show2 = !show2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text color="error" @click="exit"> Cancel </v-btn>
          <v-btn color="success" text :disabled="notEqual" @click="submit"> Save changes </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      password: '',
      show1: false,
      retype: '',
      show2: false,
    }
  },
  computed: {
    activeUser() {
      return this.$store.getters['auth/activeUser']
    },
    notEqual() {
      return this.password === '' || this.password !== this.retype
    },
  },
  methods: {
    submit() {
      var item = { id: this.activeUser.id, password: this.password }
      this.$store
        .dispatch('users/updatePassword', item)
        .then(() => {
          //action is unchanged (add, edit)
          this.exit()
        })
        .catch((err) => {
          this.$store.dispatch('setError', err)
        })
    },
    exit() {
      this.password = ''
      this.retype = ''
      this.show1 = false
      this.show2 = false
      this.dialog = false
    },
  },
}
</script>
