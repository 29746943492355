import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.disabled,"error-messages":_vm.errorMessage},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c(VDatePicker,{attrs:{"active-picker":_vm.activePicker,"no-title":"","first-day-of-week":1,"min":"1950-01-01","max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }