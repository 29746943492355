import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('delete-dialog',{attrs:{"visible":_vm.deleteDialog,"headers":_vm.headersWithoutAction,"selected-item":_vm.selectedItem,"dialog-title":'Delete ' + _vm.displayName},on:{"closeDelete":_vm.closeDelete}}),_c('edit-dialog',{attrs:{"visible":_vm.editDialog,"selected-item":_vm.selectedItem,"dialog-title":_vm.dialogText,"database-module":_vm.databaseModule},on:{"closeEdit":_vm.closeEdit}})],_c(VCard,[_c('display-header',{attrs:{"display-name":_vm.displayName + ' allocation'},on:{"onSearch":_vm.onSearch}}),_c(VCardText,{staticClass:"pb-0"},[_c(VContainer,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.allItems,"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Loading... Please wait!"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDate(item.date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.status === 'dropped' ? 'error' : 'success',"outlined":""}},[_vm._v(" "+_vm._s(item.status === 'dropped' ? 'Dropped' : 'Picked up')+" ")])]}},{key:"item.truckId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.type === 'truck' ? 'info--text' : ''},[_vm._v(_vm._s(item.truckId))])]}},{key:"item.trailerId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.type === 'truck' ? '' : 'info--text'},[_vm._v(_vm._s(item.trailerId))])]}}])})],1)],1),_c(VDivider),_c(VCardActions,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"outlined":"","fab":"","color":"success","disabled":_vm.isLoading || !_vm.isAdmin},on:{"click":function($event){$event.stopPropagation();return _vm.addItem('picked')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-truck-check ")])],1)]}}])},[_c('span',[_vm._v("Pick up "+_vm._s(_vm.displayName))])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"outlined":"","fab":"","color":"error","disabled":_vm.isLoading || !_vm.isAdmin},on:{"click":function($event){$event.stopPropagation();return _vm.addItem('dropped')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-truck-remove ")])],1)]}}])},[_c('span',[_vm._v("Drop "+_vm._s(_vm.displayName))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","outlined":"","fab":"","color":"primary","disabled":_vm.isLoading},on:{"click":_vm.reloadData}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" Refresh data ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.exit}},[_vm._v(" Exit ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }