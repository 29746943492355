<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-form ref="form" :disabled="!isAdmin">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-text>
          <validation-observer ref="observer">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Trailer Number" rules="required|alpha_dash">
                    <v-text-field
                      v-model="item.id"
                      label="Trailer Number"
                      prepend-icon="mdi-pound"
                      :disabled="edit"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <data-selector
                    label="Status"
                    database-module="trailerStatus"
                    getter="trailerStatus"
                    item-text="statusText"
                    item-value="id"
                    :not-empty="true"
                    :data.sync="item.statusId"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Trailer Make" rules="required">
                    <v-text-field v-model="item.make" label="Make" prepend-icon="mdi-truck" :error-messages="errors" />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Trailer Model" rules="required">
                    <v-text-field
                      v-model="item.model"
                      label="Model"
                      prepend-icon="mdi-truck-cargo-container"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Trailer Year" rules="required|numeric">
                    <v-text-field
                      v-model="item.year"
                      label="Year"
                      prepend-icon="mdi-calendar"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Trailer Plates" rules="required">
                    <v-text-field
                      v-model="item.plates"
                      label="Plates"
                      prepend-icon="mdi-numeric-1-box-outline"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox v-model="item.smallTrailer" label="Is small Trailer"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model.trim="item.note" auto-grow label="Note" rows="4" row-height="30" clearable>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-divider />
        <edit-actions @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import crudEdit from '../../mixins/crudEdit'
import DataSelector from './_DataSelector.vue'
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { numeric, alpha_dash } from 'vee-validate/dist/rules'

extend('numeric', {
  ...numeric,
  message: 'A number is required',
})

extend('alpha_dash', {
  ...alpha_dash,
  message: '{_field_} may contain alphabetic characters, numbers, dashes or underscores.',
})

export default {
  components: {
    DataSelector,
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [crudEdit],
  data() {
    return {
      defaultItem: {
        id: '',
        make: '',
        model: '',
        year: '',
        plates: '',
        statusId: '',
        smallTrailer: false,
      },
    }
  },
}
</script>
