import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"300px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 secondary white--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VTextarea,{attrs:{"auto-grow":"","label":"Note","rows":"4","row-height":"30","outlined":"","autofocus":""},model:{value:(_vm.itemValue),callback:function ($$v) {_vm.itemValue=$$v},expression:"itemValue"}})],1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":_vm.exit}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":_vm.submit}},[_vm._v("Save changes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }