<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="text-h5 secondary white--text">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-container>
          Are you sure you want to delete this item?
          <v-list>
            <v-list-item v-for="header in headers" :key="header.value">
              <v-list-item-content>
                <v-list-item-title>{{ header.text }}</v-list-item-title>
                <v-list-item-subtitle v-if="header.displayValue">
                  {{
                    header.formatter
                      ? header.formatter(selectedItem[header.displayValue])
                      : selectedItem[header.displayValue]
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ header.formatter ? header.formatter(selectedItem[header.value]) : selectedItem[header.value] }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="cancelDelete"> Cancel </v-btn>
        <v-btn color="success" text @click="deleteItem"> Delete </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['visible', 'headers', 'selectedItem', 'dialogTitle'],
  data() {
    return {
      deleted: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeDelete', this.deleted)
        }
      },
    },
  },
  methods: {
    cancelDelete() {
      this.deleted = false
      this.dialog = false
    },
    deleteItem() {
      this.deleted = true
      this.dialog = false
    },
  },
}
</script>
<style scoped>
.theme--dark.v-list {
  background-color: var(--v-background-base);
  color: rgba(255, 255, 255, 0.7);
}
</style>
