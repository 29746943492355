import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c('edit-header',{attrs:{"dialog-title":_vm.dialogTitle}}),_c(VCardText,[_c('validation-observer',{ref:"observer"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"auto","align-self":"center"}},[_c('Avatar',{attrs:{"username":_vm.fullName,"size":100}})],1),_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"First Name","prepend-icon":"mdi-account","error-messages":errors},model:{value:(_vm.item.firstName),callback:function ($$v) {_vm.$set(_vm.item, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.firstName"}})]}}])})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Last Name","prepend-icon":"mdi-account-multiple","error-messages":errors},model:{value:(_vm.item.lastName),callback:function ($$v) {_vm.$set(_vm.item, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.lastName"}})]}}])})],1)],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Phone number","prepend-icon":"mdi-phone-classic","error-messages":errors},model:{value:(_vm.item.phoneNumber),callback:function ($$v) {_vm.$set(_vm.item, "phoneNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.phoneNumber"}})]}}])})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"E-mail","prepend-icon":"mdi-email","error-messages":errors},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Birthday","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-birthday-picker',{attrs:{"label":"Birthday","error-message":errors},model:{value:(_vm.item.birthday),callback:function ($$v) {_vm.$set(_vm.item, "birthday", $$v)},expression:"item.birthday"}})]}}])})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('data-selector',{attrs:{"label":"Role","disabled":_vm.selectedItem.id === _vm.activeUser.id,"database-module":"roles","getter":"roles","item-text":"roleName","item-value":"id","not-empty":true,"data":_vm.item.roleId},on:{"update:data":function($event){return _vm.$set(_vm.item, "roleId", $event)}}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSwitch,{attrs:{"label":"Dispatcher","disabled":!_vm.isAdmin},model:{value:(_vm.item.isDispatcher),callback:function ($$v) {_vm.$set(_vm.item, "isDispatcher", $$v)},expression:"item.isDispatcher"}})],1),(_vm.item.isDispatcher)?_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('data-selector',{attrs:{"label":"Board","disabled":!_vm.isAdmin,"database-module":"boards","getter":"boards","item-text":"boardName","item-value":"id","not-empty":true,"data":_vm.item.boardId},on:{"update:data":function($event){return _vm.$set(_vm.item, "boardId", $event)}}})],1):_vm._e()],1)],1)],1)],1),_c(VDivider),_c('edit-actions',{attrs:{"disabled-submit":_vm.itIsI},on:{"exit":_vm.exit,"submit":_vm.onSubmit}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }