<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-text>
          <validation-observer ref="observer">
            <v-container>
              <!-- firstName and lastName with avatar -->
              <v-row justify="center">
                <v-col cols="12" sm="auto" align-self="center">
                  <Avatar :username="fullName" :size="100" />
                </v-col>
                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" name="First Name" rules="required">
                        <v-text-field
                          v-model.trim="item.firstName"
                          label="First Name"
                          prepend-icon="mdi-account"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" name="Last Name" rules="required">
                        <v-text-field
                          v-model.trim="item.lastName"
                          label="Last Name"
                          prepend-icon="mdi-account-multiple"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!--  -->

              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Phone Number" rules="required">
                    <v-text-field
                      v-model.trim="item.phoneNumber"
                      label="Phone number"
                      prepend-icon="mdi-phone-classic"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="E-mail" rules="required|email">
                    <v-text-field
                      v-model="item.email"
                      label="E-mail"
                      prepend-icon="mdi-email"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <!-- birthday picker -->
                  <!-- <birthday-picker :date.sync="item.birthday" label="Birthday" :not-empty="true" /> -->
                  <validation-provider v-slot="{ errors }" name="Birthday" rules="required">
                    <m-birthday-picker v-model="item.birthday" label="Birthday" :error-message="errors" />
                  </validation-provider>
                </v-col>

                <!-- Role selector -->
                <v-col cols="12" md="6">
                  <data-selector
                    label="Role"
                    :disabled="selectedItem.id === activeUser.id"
                    database-module="roles"
                    getter="roles"
                    item-text="roleName"
                    item-value="id"
                    :not-empty="true"
                    :data.sync="item.roleId"
                  />
                </v-col>
                <!--  -->
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-switch v-model="item.isDispatcher" label="Dispatcher" :disabled="!isAdmin" />
                </v-col>
                <!-- Board selector -->
                <v-col v-if="item.isDispatcher" cols="12" sm="6">
                  <data-selector
                    label="Board"
                    :disabled="!isAdmin"
                    database-module="boards"
                    getter="boards"
                    item-text="boardName"
                    item-value="id"
                    :not-empty="true"
                    :data.sync="item.boardId"
                  />
                </v-col>
                <!--  -->
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-divider />
        <edit-actions :disabled-submit="itIsI" @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Avatar from 'vue-avatar'
import crudEdit from '../../mixins/crudEdit'
// import BirthdayPicker from './_BirthdayPicker.vue'
import DataSelector from './_DataSelector.vue'
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'

import { email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import MBirthdayPicker from '../MBirthdayPicker.vue'

extend('email', {
  ...email,
  message: 'E-mail must be valid',
})

export default {
  components: {
    Avatar,
    // BirthdayPicker,
    DataSelector,
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
    MBirthdayPicker,
  },
  mixins: [crudEdit],
  props: {
    ...crudEdit.props,
    itIsI: Boolean,
  },
  data() {
    return {
      defaultItem: {
        id: '',
        firstName: '',
        lastName: '',
        birthday: '',
        phoneNumber: '',
        email: '',
        isDispatcher: false,
        roleId: '',
        boardId: '',
      },
    }
  },
  computed: {
    fullName() {
      return this.item.firstName + ' ' + this.item.lastName
    },
    activeUser() {
      return this.$store.getters['auth/activeUser']
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
  },
  methods: {
    submitFormatter(item) {
      if (item.birthday) {
        item.birthday = new Date(item.birthday).toISOString()
      }
      if (!item.isDispatcher) {
        item.boardId = ''
      }
    },
    updateFormatter(item) {
      if (item.birthday) {
        item.birthday = item.birthday.substr(0, 10)
      }
    },
  },
}
</script>
