import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"info","block":""}},'v-btn',attrs,false),on),[_vm._v(" Change password ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 secondary white--text"},[_vm._v(" Change password ")]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"name":"inputPassword","label":"Type new password","hint":"At least 8 characters","counter":"","prepend-icon":"mdi-lock"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"name":"inputRetype","label":"Retype password","counter":"","prepend-icon":"mdi-lock"},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.retype),callback:function ($$v) {_vm.retype=$$v},expression:"retype"}})],1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":_vm.exit}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success","text":"","disabled":_vm.notEqual},on:{"click":_vm.submit}},[_vm._v(" Save changes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }