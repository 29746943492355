<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="text-h5 secondary white--text"> Reset password? </v-card-title>
      <v-card-text>
        <v-container>
          <p>
            Are you sure you want to reset password for user:
            <strong>{{ selectedItem.firstName + ' ' + selectedItem.lastName }}</strong>
          </p>
          <p>
            With role <strong>{{ selectedItem.roleName }}</strong>
          </p>
          <p class="success--text text-body-1">
            New password will be sent to e-mail: <strong>{{ selectedItem.email }}.</strong>
          </p>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="cancelDelete">Cancel</v-btn>
        <v-btn color="success" text @click="deleteItem">Reset</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['visible', 'selectedItem'],
  data() {
    return {
      deleted: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeReset', this.deleted)
        }
      }
    }
  },
  methods: {
    cancelDelete() {
      this.deleted = false
      this.dialog = false
    },
    deleteItem() {
      this.deleted = true
      this.dialog = false
    }
  }
}
</script>
