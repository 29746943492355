<template>
  <div>
    <contact-dialog
      :driver-id="driverId"
      :driver-text="driverFullName"
      :visible="contactDialog"
      @closeContact="closeContact"
    />
    <common-display
      ref="display"
      :edit-path="editDialog"
      :max-width="maxWidth"
      :database-module="databaseModule"
      :display-name="displayName"
      :headers="headers"
    >
      <!-- Birthday -->
      <template v-slot:item.birthday="{ item }">
        {{ formattedDate(item.birthday) }}
      </template>
      <!--  -->

      <!-- Added Contact info button in actions -->
      <template v-slot:addedActions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" small color="info" v-bind="attrs" @click.stop="contactInfo(item)" v-on="on">
              mdi-account-box
            </v-icon>
          </template>
          <span>Contact Info</span>
        </v-tooltip>
      </template>
      <!--  -->
    </common-display>
  </div>
</template>

<script>
import CommonDisplay from './_CommonDisplay.vue'
import ContactDialog from './driver-contact-info-display.vue'
import dateF from '../../services/date.service'

export default {
  components: {
    CommonDisplay,
    ContactDialog,
  },
  data() {
    return {
      /*****  change when copying *****/
      databaseModule: 'drivers',
      displayName: 'Driver',
      editDialog: 'DriverEdit.vue',
      maxWidth: '1200px',
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Birthday', value: 'birthday', formatter: dateF.formatBirthday },
        { text: 'Truck#', value: 'truckId' },
        { text: 'Trailer', value: 'trailerId' },
        { text: 'Dispatcher', value: 'dispatcherFullName' },
        { text: 'Credentials', value: 'credential' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      /*******************************************/
      contactDialog: false,
      driverFullName: '',
      driverId: '',
    }
  },
  methods: {
    contactInfo(item) {
      this.driverFullName = item.firstName + ' ' + item.lastName + ' ' + item.credential
      this.driverId = item.id
      this.contactDialog = true
      //this.$refs.display.dialog = false
    },
    closeContact() {
      //this.$refs.display.dialog = true
      this.contactDialog = false
    },
    formattedDate(dateString) {
      return dateF.formatBirthday(dateString)
    },
  },
}
</script>
