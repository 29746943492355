<template>
  <v-autocomplete
    v-model="selectedValue"
    :items="allItems"
    :item-text="itemText"
    :item-value="itemValue"
    :loading="isLoading"
    :label="label"
    :disabled="disabled"
    append-outer-icon="mdi-refresh"
    :error-messages="errorMessages"
    @click:append-outer="reloadItems(true)"
  />
</template>

<script>
export default {
  props: [
    'value',
    'getter',
    'databaseModule',
    'itemText',
    'itemValue',
    'label',
    'disabled',
    'alwaysReload',
    'filterParams',
    'initialize',
    'errorMessages',
  ],
  data() {
    return {
      isLoading: false,
      needUpdate: true,
      selectedValue: '',
    }
  },
  computed: {
    allItems() {
      return this.$store.getters[this.databaseModule + '/' + this.getter]
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: 'handleInput',
    },
    filterParams() {
      this.reloadItems(true)
    },
    selectedValue(newVal) {
      // console.log('MDataSelector value = ', newVal)
      this.needUpdate = false
      this.$emit('input', newVal)
    },
  },
  mounted() {
    this.reloadItems(this.alwaysReload)
  },
  methods: {
    handleInput(value) {
      if (this.needUpdate) this.selectedValue = value
      else this.needUpdate = true
    },
    reloadItems(forceReload) {
      if (this.isLoading) return
      this.isLoading = true

      if (!this.allItems?.length || forceReload) {
        let filter = this.filterParams ? this.filterParams : {}
        let filtered = this.filterParams ? true : false
        filter._filtered = filtered
        this.$store
          .dispatch(this.databaseModule + '/getItems', filter)
          .then(() => {
            if (this.initialize && this.allItems.length) {
              // console.log('Initialize value ', this.allItems[0].id)
              this.selectedValue = this.allItems[0].id
            }
          })
          .catch((err) => {
            this.$store.dispatch('setError', err)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        if (this.initialize && this.allItems.length) {
          // console.log('Initialize value ', this.allItems[0].id)
          this.selectedValue = this.allItems[0].id
        }
        this.isLoading = false
      }
    },
  },
}
</script>

<style></style>
